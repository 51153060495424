(function ($, undefined) {
    "use strict";

    var brandFilter = {
        divBrandSearch: function () {
            return $("#divBrandSearch");
        },
        aSortQuantityOnBrands: function () {
            return $("#aSortQuantityOnBrands");
        },
        aSortNameOnBrands: function () {
            return $("#aSortNameOnBrands");
        },
        divBrandNotFound: function () {
            return $("#divBrandNotFound");
        },
        txtBrandSearch: function () {
            return $("#txtBrandSearch");
        },
        spnClearBrandFilter: function () {
            return $("#spnClearBrandFilter");
        },
        filtersList: function () {
            return $("div.FilterListItem.ABRA");
        },
        spnSearchIcon: function () {
            return $("#spnSearchIcon");
        },
        listContainer: function () {
            return brandFilter.txtBrandSearch().parents(".productFilterList")[0];
        },
        btnSortNameClicked: function (ascendic) {
            brandFilter.aSortQuantityOnBrands().removeClass("active");
            brandFilter.aSortNameOnBrands().addClass("active");
            brandFilter.sortBrandsByName(ascendic);
        },
        bindEvents: function () {
            var isSortedByCount = true;
            var isSortedByName = false;

            brandFilter.txtBrandSearch().bind("keyup", function () {
                brandFilter.searchBrands(this.value);
            });

            brandFilter.spnClearBrandFilter().click(function () {
                brandFilter.txtBrandSearch().val("");
                brandFilter.searchBrands("");
            });

            brandFilter.aSortQuantityOnBrands().click(function () {
                brandFilter.aSortNameOnBrands().removeClass("active");
                brandFilter.aSortQuantityOnBrands().addClass("active");
                brandFilter.sortBrandsByCount(isSortedByCount);
                isSortedByCount = !isSortedByCount;
                isSortedByName = false;
            });

            brandFilter.aSortNameOnBrands().click(function () {
                brandFilter.btnSortNameClicked(isSortedByName);
                isSortedByName = !isSortedByName;
                isSortedByCount = false;
            });
        },
        searchBrands: function (val) {
            var $brandDivs = brandFilter.filtersList();
            if (val.length > 0) {
                var isSearchMatch = false;
                $brandDivs.hide();
                $brandDivs.each(function () {
                    var regexSearch = RegExp("\\s*" + val, "i");
                    var spnFilterName = $(this).find("span.FilterName");
                    var brandName = spnFilterName.text();
                    if (brandName.search(regexSearch) !== -1) {
                        $(this).show();
                        isSearchMatch = true;
                    } else {
                        if (spnFilterName.parent("span").hasClass("SelectedFilter")) {
                            $(this).show();
                        }
                    }
                });
                brandFilter.showHideNoResultsFoundMessage(!isSearchMatch);
            } else {
                brandFilter.resetBrandSearch($brandDivs);
            }
        },
        resetBrandSearch: function ($brandDivs) {
            brandFilter.showHideNoResultsFoundMessage(false);
            $brandDivs.show();
            brandFilter.spnClearBrandFilter().hide();
            brandFilter.spnSearchIcon().show();
        },
        showHideNoResultsFoundMessage: function (showMessage) {
            if (showMessage) {
                var notFoundMessage = brandFilter.txtBrandSearch().data("brandnotfound");
                brandFilter.divBrandNotFound().html(notFoundMessage);
                brandFilter.divBrandNotFound().show();
            } else {
                brandFilter.divBrandNotFound().html("");
                brandFilter.divBrandNotFound().hide();
            }
            brandFilter.spnClearBrandFilter().show();
            brandFilter.spnSearchIcon().hide();
        },
        brandSorterQuantity: function (a, b) {
            if (a.getAttribute("data-productcount") == b.getAttribute("data-productcount")) {
                return a
                    .getAttribute("data-productname")
                    .toUpperCase()
                    .localeCompare(b.getAttribute("data-productname").toUpperCase());
            } else {
                return b.getAttribute("data-productcount") - a.getAttribute("data-productcount");
            }
        },
        brandSorterName: function (a, b) {
            return a
                .getAttribute("data-productname")
                .toUpperCase()
                .localeCompare(b.getAttribute("data-productname").toUpperCase());
        },
        sortBrandsByCount: function (ascendic) {
            var sortedDivs = brandFilter.filtersList().toArray().sort(brandFilter.brandSorterQuantity);
            if (ascendic) {
                sortedDivs = sortedDivs.reverse();
            }
            brandFilter.repopulateListContainer(sortedDivs);
        },
        sortBrandsByName: function (ascendic) {
            var sortedDivs = brandFilter.filtersList().toArray().sort(brandFilter.brandSorterName);
            if (ascendic) {
                sortedDivs = sortedDivs.reverse();
            }
            brandFilter.repopulateListContainer(sortedDivs);
        },
        repopulateListContainer: function (sortedDivs) {
            brandFilter.filtersList().remove();

            $.each(sortedDivs, function (index, value) {
                brandFilter.listContainer().appendChild(value);
            });
        },
        init: function () {
            brandFilter.bindEvents();
        },
    };

    $(document).ready(function () {
        if (brandFilter.divBrandSearch().length > 0) {
            brandFilter.init();
        }
    });
})(window.jQuery);
