(function (window, $, undefined) {
    "use strict";

    var cookieName = "ActiveColumnsCookie",
        columns = "4",
        mob_columns = "1";

    $(function () {
        try {
            setInitialSelectedColumn($("#activecolumns").val(), $("#activemobilecolumns").val()); // set default column css class
        } catch (e) {}
    });

    function BuildLayout() {
        var activeColumns = $("#activecolumns");
        columns = activeColumns.val();
        mob_columns = $("#activemobilecolumns").val();

        //set container class which controls <li> widths.
        $("#productlistcontainer").attr("class", "columns" + columns + " columnsmobile" + mob_columns);

        $(".rtimg").each(function () {
            var re = new RegExp("(?:_)(.*?)(?=pl)", "gi");
            var value = re.exec($(this).attr("src"));
            if (value != null)
                $(this).attr(
                    "src",
                    $(this)
                        .attr("src")
                        .replace(value[0], "_" + columns),
                );
        });

        $(".rtSashImg").each(function () {
            var re = new RegExp("(_[^_d]sh)(.*?)$", "gi");
            var value = re.exec($(this).attr("src"));
            if (value != null)
                $(this).attr(
                    "src",
                    $(this)
                        .attr("src")
                        .replace(value[1], "_" + columns + "sh"),
                );
        });

        activeColumns.change();
    }

    function ToggleColumn(num) {
        try {
            var activeMobileColumnsVal = $("#activemobilecolumns").val(),
                activeColumnsVal = $("#activecolumns").val();
            if (num < 3) {
                // toggle for mobile
                if (activeMobileColumnsVal == num) return;
                addOrRemoveSelectedColumnIcon(activeColumnsVal, num);
                processColumnsLayout(activeColumnsVal, num);
            } else {
                // toggle for desktop
                if (activeColumnsVal == num) return;

                addOrRemoveSelectedColumnIcon(num, activeMobileColumnsVal);
                processColumnsLayout(num, activeMobileColumnsVal);
            }
            $(window).trigger("changeActiveColumns");
        } catch (err) {}
    }

    function addOrRemoveSelectedColumnIcon(desktop, mobile) {
        $("ul.columnselector > li").removeClass("selected");
        $("li.colopt" + desktop).addClass("selected");
        $("li.coloptmob" + mobile).addClass("selected");
    }

    function processColumnsLayout(desktop, mobile) {
        writeToggleColumnCookie(desktop, mobile);
        $("#activecolumns").val(desktop).change();
        $("#activemobilecolumns").val(mobile);
        BuildLayout();
    }

    function setInitialSelectedColumn(desktop, mobile) {
        //set container class which controls <li> widths.
        $("#productlistcontainer").attr("class", "columns" + desktop + " columnsmobile" + mobile);
        if ($("#initialActiveColumns").val() != desktop) {
            processColumnsLayout(desktop, mobile);
        }
        addOrRemoveSelectedColumnIcon(desktop, mobile);
    }

    function writeToggleColumnCookie(desktop, mobile) {
        var noOfDaysToExpire = 1;
        var pathValue = "/";
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + noOfDaysToExpire);
        var cookieValue =
            escape(desktop) + "$" + escape(mobile) + "; expires=" + exdate.toUTCString() + "; path=" + pathValue;
        document.cookie = cookieName + "=" + cookieValue;
    }

    // Expose globals
    window.ToggleColumn = ToggleColumn;
})(window, window.jQuery);
