/*! jQuery UI - v1.10.3 - 2013-05-03
 * http://jqueryui.com
 * Copyright 2013 jQuery Foundation and other contributors; Licensed MIT */
(function (e) {
    var t = !1;
    e(document).mouseup(function () {
        t = !1;
    }),
        e.widget("ui.mouse", {
            version: "1.10.3",
            options: { cancel: "input,textarea,button,select,option", distance: 1, delay: 0 },
            _mouseInit: function () {
                var t = this;
                this.element
                    .bind("mousedown." + this.widgetName, function (e) {
                        return t._mouseDown(e);
                    })
                    .bind("click." + this.widgetName, function (i) {
                        return !0 === e.data(i.target, t.widgetName + ".preventClickEvent")
                            ? (e.removeData(i.target, t.widgetName + ".preventClickEvent"),
                              i.stopImmediatePropagation(),
                              !1)
                            : undefined;
                    }),
                    (this.started = !1);
            },
            _mouseDestroy: function () {
                this.element.unbind("." + this.widgetName),
                    this._mouseMoveDelegate &&
                        e(document)
                            .unbind("mousemove." + this.widgetName, this._mouseMoveDelegate)
                            .unbind("mouseup." + this.widgetName, this._mouseUpDelegate);
            },
            _mouseDown: function (i) {
                if (!t) {
                    this._mouseStarted && this._mouseUp(i), (this._mouseDownEvent = i);
                    var s = this,
                        n = 1 === i.which,
                        a =
                            "string" == typeof this.options.cancel && i.target.nodeName
                                ? e(i.target).closest(this.options.cancel).length
                                : !1;
                    return n && !a && this._mouseCapture(i)
                        ? ((this.mouseDelayMet = !this.options.delay),
                          this.mouseDelayMet ||
                              (this._mouseDelayTimer = setTimeout(function () {
                                  s.mouseDelayMet = !0;
                              }, this.options.delay)),
                          this._mouseDistanceMet(i) &&
                          this._mouseDelayMet(i) &&
                          ((this._mouseStarted = this._mouseStart(i) !== !1), !this._mouseStarted)
                              ? (i.preventDefault(), !0)
                              : (!0 === e.data(i.target, this.widgetName + ".preventClickEvent") &&
                                    e.removeData(i.target, this.widgetName + ".preventClickEvent"),
                                (this._mouseMoveDelegate = function (e) {
                                    return s._mouseMove(e);
                                }),
                                (this._mouseUpDelegate = function (e) {
                                    return s._mouseUp(e);
                                }),
                                e(document)
                                    .bind("mousemove." + this.widgetName, this._mouseMoveDelegate)
                                    .bind("mouseup." + this.widgetName, this._mouseUpDelegate),
                                i.preventDefault(),
                                (t = !0),
                                !0))
                        : !0;
                }
            },
            _mouseMove: function (t) {
                return e.ui.ie && (!document.documentMode || 9 > document.documentMode) && !t.button
                    ? this._mouseUp(t)
                    : this._mouseStarted
                    ? (this._mouseDrag(t), t.preventDefault())
                    : (this._mouseDistanceMet(t) &&
                          this._mouseDelayMet(t) &&
                          ((this._mouseStarted = this._mouseStart(this._mouseDownEvent, t) !== !1),
                          this._mouseStarted ? this._mouseDrag(t) : this._mouseUp(t)),
                      !this._mouseStarted);
            },
            _mouseUp: function (t) {
                return (
                    e(document)
                        .unbind("mousemove." + this.widgetName, this._mouseMoveDelegate)
                        .unbind("mouseup." + this.widgetName, this._mouseUpDelegate),
                    this._mouseStarted &&
                        ((this._mouseStarted = !1),
                        t.target === this._mouseDownEvent.target &&
                            e.data(t.target, this.widgetName + ".preventClickEvent", !0),
                        this._mouseStop(t)),
                    !1
                );
            },
            _mouseDistanceMet: function (e) {
                return (
                    Math.max(
                        Math.abs(this._mouseDownEvent.pageX - e.pageX),
                        Math.abs(this._mouseDownEvent.pageY - e.pageY),
                    ) >= this.options.distance
                );
            },
            _mouseDelayMet: function () {
                return this.mouseDelayMet;
            },
            _mouseStart: function () {},
            _mouseDrag: function () {},
            _mouseStop: function () {},
            _mouseCapture: function () {
                return !0;
            },
        });
})(jQuery);
