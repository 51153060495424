(function (window, $, Handlebars, segment, hashService, algoliaUtil, plpCategoryHeader, undefined) {
    "use strict";

    var dcpVal,
        dpppVal = "24",
        isScrollToTopEnable = true,
        hasUserInteractedWithFilters = false,
        noOfRowsToScroll = 3,
        scrollHeight = 0,
        contractFiltersOnOtherExpansionMobile = false,
        productListingScrollTopOnFilterChange = true,
        mobileCloseFiltersOnClear = false,
        productListingScrollToProductList = false;
    var sortOptionsTemplateEnabled;
    var toggleMenuEnabled;
    var stickyMenuEnabled;
    var fullWidthStickyMenuEnabled;
    var hideToggleMenuOnScrollEnabled;
    var productsPerPageInProductCountEnabled;
    var toggleDesktopFiltersEnabled;
    var viewNumberType;
    var recentlyViewedProductsHighlightEnabled;
    var recentlyViewedProductsHighlightText;
    var mobFiltersContainerEle = $(".mobSelectedFiltersContainer");
    var contentWrapperEle = $(".ContentWrapper");
    var showFilterSectionClear = $("#hdnShowFilterSectionClear").val().toLowerCase() === "true";

    var filters = {};

    //** Example data structure
    //	{
    //		"AFLOR": {
    //			type: "fixed",
    //			keys: ["Mens", "Womens"]
    //		},
    //		"ABRA": {
    //			type: "variable",
    //			keys: ["adidas", "Nike"]
    //		},
    //		"ACOL": {
    //			type: "variable",
    //			keys: ["Blue", "Black"]
    //		}
    //	}
    //**

    var useLegacyFilters;
    var isSearch;
    var isLegacy;
    var deindexFilters;

    var refineAndSortTransitionEnabled = $("#hdnRefineAndSortTransitionEnabled").val() === "true";
    var closeFiltersOnSort = $("#hdnCloseFiltersOnSort").val() === "true";
    var closeSortFilterOnSelectMob = $("#hdnCloseSortFilterOnSelectMob").val() === "true";
    var selectedFilterCount = $("#hdnSelectedFilterCount").val() === "true";
    var removeBracketsFromAppliedFilterCounts =
        $("#hdnRemoveBracketsFromAppliedFilterCounts").val() === "true"; /* For applied filters */
    var filteredProductsCountWithoutBrackets =
        $("#hdnFilteredProductsCountWithoutBrackets").val() === "true"; /* For amount of products for each filter */
    let readMoreReadLessButton = null;
    let categoryTextWrapper = null;
    let categoryTextParentWrapper = null;

    if (window.location.search.indexOf("showfilterandsort=true") > -1) {
        OpenMobFilterDropDown(refineAndSortTransitionEnabled);
    }

    // Bind the event.
    $(window).on("hashchange", function (e) {
        if (isLegacy) {
            handleHashChange(true);
        }
    });
    $(window).on("popstate", function (e) {
        // On pop, grab the state from history
        // and override filters
        if (!isLegacy) {
            var state = e.originalEvent.state;
            if (state) {
                filters = state;
                handleHashChange(true);
            } else {
                // Clear the filters, and rebuild them from the path
                clearFiltersSelection(null, true);
                var filtersPath = location.pathname.replace(getBaseCategoryUrl($), "");
                calcFiltersFromPath(filtersPath, true, true, true);
                buildFiltersObject();
                handleHashChange(true);
            }
        }
    });

    // Apply default text from filters
    $(document).ready(function () {
        applySortFilterText();
        setupToggleMenu();
        setupSortOptionDropdown();
        contractFiltersOnOtherExpansionMobile = $("#hdnContractFiltersOnOtherExpansionMobile").val();
        mobileCloseFiltersOnClear = $("#hdnProductListingMobileCloseFiltersOnClear").val().toLowerCase() === "true";
        if ($("#hdnProductListingScrollTopOnFilterChange").val() !== undefined) {
            productListingScrollTopOnFilterChange =
                $("#hdnProductListingScrollTopOnFilterChange").val().toLowerCase() === "true";
        }
        if ($("#hdnProductListingScrollToProductList").val() !== undefined) {
            productListingScrollToProductList =
                $("#hdnProductListingScrollToProductList").val().toLowerCase() === "true";
        }
        recentlyViewedProductsHighlightEnabled =
            $("#hdnRecentlyViewedProductsHighlightEnabled").val().toLowerCase() === "true";
        recentlyViewedProductsHighlightText = $("#hdnRecentlyViewedProductsHighlightText").val();
        if (recentlyViewedProductsHighlightEnabled) {
            highlightRecentlyViewedProducts();
        }

        // SEO Filters
        highlightProductsPerPageValue();

        useLegacyFilters = $("#hdnUseLegacyFilters").val() == "true";
        deindexFilters = $("#hdnDeindexFilters").val() == "true";
        isSearch = $("body").hasClass("browse-search");
        // TODO: Use a more guaranteed value for search page type.
        isLegacy = useLegacyFilters || deindexFilters || isSearch || !window.history;

        // generateFilterUrls();
        if (!isLegacy) {
            // Pre generate the filters
            buildFiltersObject();
            if (!isBot()) {
                populateBreadcrumb();
            }
        }

        addFilterShowMore();

        setupCategoryCopyReadMoreLess();
        InitializeSegmentEvents();
        plpCategoryHeader.initCategoryHeaderSwiper();
        toggleDesktopFilters();
    });

    function addFilterShowMore() {
        $(".FilterShowMore").each(function () {
            var $el = $(this);
            $el.click(function (e) {
                e.preventDefault();
                var filterCat = $el.data("key");
                $el.parents(".productFilter").addClass("category-" + filterCat);
                $("." + $el.attr("data-key")).show();
                $el.hide();
            });
        });
    }

    function highlightRecentlyViewedProducts() {
        try {
            var productsJson = localStorage.getItem("recentlyViewedProducts");
            if (!productsJson) return;
            var savedProductsList = JSON.parse(productsJson);
            if (savedProductsList) {
                for (var i = 0; i < savedProductsList.length; i++) {
                    var id = savedProductsList[i].id;
                    var $productItem = $("#productlistcontainer ul li[li-productid=" + id + "]");
                    if ($productItem.length > 0) {
                        var $thumb = $productItem.children(".s-productthumbbox");
                        $thumb.css("border", "1px solid black");
                        $productItem.append(
                            '<div class="recentlyViewed" style="text-align: center; padding 5px; background-color: black; color: white"><span>' +
                                recentlyViewedProductsHighlightText +
                                "</span></div>",
                        );
                    }
                }
            }
        } catch (ex) {}
    }

    function getBaseCategoryUrl() {
        return $("#hdnBaseCategoryUrl").val();
    }

    function generateFilterUrls() {
        if (useLegacyFilters || deindexFilters || isSearch) return;

        var path = removeTrailingSlash(location.pathname);

        // Work out what types of filter are in the last part(s) of the URL
        var filterTypes = getFilterTypesFromPath();

        var $selectedFiltersQuery = $(
            "#filterlist div.FilterListItem span.SelectedFilter, #filterlist div.FilterListItem span.SelectableFilter",
        );
        var disableFiltersWithZeroCount = $("#hdnDisableFiltersWithZeroCount").val() === "True";

        const allowDisableFilterUrl = $("#hdnDisableFiltersWithZeroCount").val() === "True";
        const disableFilterUrlThreshold = parseInt($("#hdnDisableFilterUrlThreshold").val());

        $selectedFiltersQuery.each(function () {
            var $el = $(this);
            var finalPath = path;
            var dataItem = $el.attr("data-item");
            var filterType = $el.attr("data-filter-type");
            // Get the parent link
            var $parentLink = $(this).closest("a");
            // For bots, if the filter has no products,
            // mark it as de-indexed
            var $filterDiv = $(this).closest("div");
            var productCount = $filterDiv.attr("data-productcount");
            if ((isBot() || disableFiltersWithZeroCount) && (!productCount || productCount == "0")) {
                filterType = "deindexed";
            }

            // Are we building a fixed filter href?
            if (filterType === "fixed") {
                // Is it selectable?
                if ($el.hasClass("SelectableFilter")) {
                    // URL has fixed but no variable filters -
                    // remove the last item, add the item in question
                    if (filterTypes["fixed"] && !filterTypes["variable"]) {
                        finalPath = removeLastItemFromPath(finalPath);
                        finalPath += "/" + formatPath(dataItem.split("^")[1].toLowerCase().trim(), true);
                        // URL has variable but no fixed filters -
                        // store and remove the last item, add the item in question + the last item
                    } else if (filterTypes["variable"] && !filterTypes["fixed"]) {
                        var lastItem = getLastItemFromPath(path);
                        finalPath = removeLastItemFromPath(path);
                        finalPath +=
                            "/" + formatPath(dataItem.split("^")[1].toLowerCase().trim(), true) + "/" + lastItem;
                        // URL has variable and fixed filters -
                        // store and remove the last item, remove the parent item,
                        // then add the item in question + the last item
                    } else if (filterTypes["variable"] && filterTypes["fixed"]) {
                        var lastItem = getLastItemFromPath(path);
                        finalPath = removeLastItemFromPath(path);
                        finalPath = removeLastItemFromPath(finalPath);
                        finalPath +=
                            "/" + formatPath(dataItem.split("^")[1].toLowerCase().trim(), true) + "/" + lastItem;
                    }
                    // Just add the item in question
                    else {
                        finalPath += "/" + formatPath(dataItem.split("^")[1].toLowerCase().trim(), true);
                    }
                } else {
                    // Just remove the parent item
                    // TODO: Make this aware of fixed/variable filter combos
                    finalPath = removeLastItemFromPath(finalPath);
                }
            }
            // or a variable?
            else if (filterType === "variable") {
                if ($el.hasClass("SelectableFilter")) {
                    if (filterTypes["variable"]) {
                        finalPath = removeLastItemFromPath(finalPath);
                    }
                    finalPath += "/" + formatPath(dataItem.split("^")[1].toLowerCase().trim(), true);
                } else {
                    finalPath = removeLastItemFromPath(finalPath);
                }
            } else {
                finalPath = "";
            }
            // Set the href of the parent link
            if (
                finalPath &&
                !(
                    allowDisableFilterUrl &&
                    productCount !== undefined &&
                    parseInt(productCount) <= disableFilterUrlThreshold
                )
            ) {
                $parentLink.attr("href", finalPath);
            } else {
                $parentLink.removeAttr("href");
            }
        });
    }

    function isBot() {
        // Testing
        // navigator.__defineGetter__('userAgent', function () {
        // return "GoogleBot"
        // });

        var isBot = /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i.test(navigator.userAgent);

        return isBot;
    }

    function removeLastItemFromPath(path) {
        var pathArray = path.split("/");
        pathArray.pop();
        return pathArray.join("/");
    }

    function getLastItemFromPath(path) {
        var pathArray = path.split("/");
        return pathArray[pathArray.length - 1];
    }

    function getFilterTypesFromPath() {
        // Get fixed/variable filters
        // from the original path

        var path = removeTrailingSlash(location.pathname).replace(getBaseCategoryUrl(), "");
        var pathArray = path.split("/");
        var filterTypesList = {};

        $.each(pathArray, function (i, item) {
            // Match any fixed/variable filter value
            $(
                "#filterlist div.FilterListItem span.SelectedFilter, #filterlist div.FilterListItem span.SelectableFilter",
            ).each(function () {
                var $el = $(this);
                var $filterName = $el.find("span.FilterName");
                var group = $el.attr("data-item")?.split("^")[1];
                var filterType = $el.attr("data-filter-type");
                var urlOrder = $el.attr("data-url-order");
                if (
                    formatPath($filterName.attr("data-filtername")?.toLowerCase().trim(), true) === item?.toLowerCase()
                ) {
                    filterTypesList[filterType] = {
                        name: group,
                        order: urlOrder,
                    };
                }
            });
        });

        return filterTypesList;
    }

    function handleHashChange(onNavigation) {
        var defaultFilter = "none";
        var hashOptions = hashService.deserialiseLocation();
        if (hashOptions.Filter === undefined) {
            hashOptions.Filter = defaultFilter;
        }

        if (hashOptions.dcp !== undefined && hashOptions.dppp !== undefined && hashOptions.OrderBy !== undefined) {
            if (hashOptions.dcp.length > 0) {
                dcpVal = hashOptions.dcp;
                dpppVal = hashOptions.dppp;
                setOrderByDdlValue(hashOptions.OrderBy);
                highlightProductsPerPageValue(dpppVal);
                // setProductsPerPageValue(dpppVal); TODO: Why did we need this?
                processHashChange(hashOptions, onNavigation);
            }
        } else {
            //no hash or default hash
            var currentPageParms,
                defaultOrderBy = "price_asc",
                defaultPageNumber = 1;
            var defaultPageLength = getBrowseLowPageLength();
            var defaultPageNoValue = getProductListContainerAttributeValue("defaultpageno"),
                defaultPageLengthValue = getProductListContainerAttributeValue("defaultpagelength"),
                defaultSortOrderValue = getProductListContainerAttributeValue("defaultsortorder"),
                defaultFilterValue = getProductListContainerAttributeValue("defaultfilter");
            if (defaultPageNoValue != null && defaultPageNoValue.length > 0) {
                defaultPageNumber = defaultPageNoValue;
            }
            if (defaultPageLengthValue != null && defaultPageLengthValue.length > 0) {
                defaultPageLength = defaultPageLengthValue;
            }
            if (defaultSortOrderValue != null && defaultSortOrderValue.length > 0) {
                defaultOrderBy = defaultSortOrderValue;
            }
            if (defaultFilterValue != null && defaultFilterValue.length > 0) {
                defaultFilter = defaultFilterValue;
            }
            currentPageParms =
                "dcp=" +
                defaultPageNumber +
                "&dppp=" +
                defaultPageLength +
                "&OrderBy=" +
                defaultOrderBy +
                "&Filter=" +
                defaultFilter;

            if (currentPageParms.length > 0) {
                var pageLoadParamsObj = hashService.deserialiseParams(currentPageParms, true);
                if (
                    pageLoadParamsObj.dcp !== undefined &&
                    pageLoadParamsObj.dppp !== undefined &&
                    pageLoadParamsObj.OrderBy !== undefined
                ) {
                    dcpVal = pageLoadParamsObj.dcp > 0 ? pageLoadParamsObj.dcp : 1;
                    dpppVal = pageLoadParamsObj.dppp;
                    setOrderByDdlValue(pageLoadParamsObj.OrderBy);
                    highlightProductsPerPageValue(dpppVal);
                    // setProductsPerPageValue(dpppVal);
                    processHashChange(pageLoadParamsObj);
                }
            }
        }
    }

    function processHashChange(hashOptions, onNavigation) {
        if (hashOptions.Filter !== undefined) {
            if (hashOptions.Filter.length > 0) {
                if (hashOptions.Filter === "none") {
                    clearFiltersSelection(null, true);
                }
                var selectedFiltersState = hashOptions.Filter.split("|");
                if (selectedFiltersState != null && selectedFiltersState.length > 0) {
                    clearFiltersSelection(null, onNavigation);
                    if (isLegacy) {
                        setFiltersSelection(selectedFiltersState);
                    } else {
                        if (isEmpty(filters)) {
                            // attempt to set filters from hash state
                            setFiltersSelection(selectedFiltersState);
                        } else {
                            setFiltersSelection();
                        }
                    }
                }
            }
        } else {
            clearFiltersSelection();
        }
        SetVal();
    }

    function pushState(option, url, filters) {
        // If legacy mode, just trigger a hash change
        // otherwise use the history API
        if (isLegacy) {
            window.location.hash = filters;
        } else {
            if (filters) {
                url += "#" + filters;
            }
            window.history.pushState(option, "", url);
            handleHashChange(true);
        }
    }

    function collapseMobileFilters() {
        $(".productFilterTitleBox").siblings("li.productFilter .productFilterList").hide();
        $("li.productFilter .productFilterTitleBox")
            .addClass("FilterClosed")
            .each(function () {
                setFilterOpenCloseState(this);
            });
    }

    function resetMobileFilters() {
        SetVal(null, "CLEAR", "###");
        if (mobileCloseFiltersOnClear) {
            CloseMobFilterDropDown(refineAndSortTransitionEnabled);
        }

        var mobApplyButton = $("ul#mobFilterControls li#mobappfltrs span.mobApplyButton");
        var mobApplyButtonText = $("ul#mobFilterControls li#mobappfltrs #hdnMobApplyButtonText").val();
        $(mobApplyButton).text(mobApplyButtonText);
    }

    function setFilterOpenCloseState(productFilterTitleBox) {
        var clickToOpenText = $("#filterlist").data("clicktoopentext");
        var clickToCloseText = $("#filterlist").data("clicktoclosetext");

        if ($(productFilterTitleBox).hasClass("FilterClosed")) {
            $(productFilterTitleBox).find(".FilterCollapseText").html(clickToOpenText);
        } else {
            $(productFilterTitleBox).find(".FilterCollapseText").html(clickToCloseText);
            if (contractFiltersOnOtherExpansionMobile === "true" && window.innerWidth < 1022) {
                setTimeout(function () {
                    var filterParent =
                        $(productFilterTitleBox)?.parent("li.productFilter")[0] ??
                        $(productFilterTitleBox)?.parent(".MobSortSelector")[0];

                    $("#FilterContainer").animate({ scrollTop: filterParent?.offsetTop });
                }, 325);
            }
        }
    }

    function setupFilterContainer() {
        var filterContainerClass = "ProductFiltersCount-" + $("#filterlist > li").length;
        $("#innerfiltercontainer").addClass(filterContainerClass);
    }

    function InitializeHideMenuOnScroll() {
        var lastScrollTopFilter = $(window).scrollTop();

        var elementTop = $(".FilterProductsTable").offset().top;
        var viewportTop = $(window).scrollTop() + 100;

        if ((window.innerWidth > 1021 || $(".persistentLevel2Selection").length > 0) && viewportTop > elementTop) {
            // turn to sticky
            $("#fixed-filters").addClass("fixed-filters-on fixed-filters-hidden");
            $("#HeaderGroup").addClass("header-hidden");
        }

        $(window).scroll(function (e) {
            // If scroll scrolls Refine off top
            elementTop = $(".FilterProductsTable").offset().top;
            viewportTop = $(window).scrollTop() + 100;

            if (window.innerWidth > 1021 || $(".persistentLevel2Selection").length > 0) {
                if (viewportTop > elementTop) {
                    // turn to sticky
                    $("#fixed-filters").addClass("fixed-filters-on");
                    if ($("#HeaderGroup").hasClass("header-hidden")) {
                        $("#fixed-filters").addClass("fixed-filters-hidden");
                    }
                } else {
                    // turn sticky off
                    $("#fixed-filters").removeClass("fixed-filters-on");
                    $("#HeaderGroup").removeClass("header-hidden");
                }

                var st = $(this).scrollTop();
                if (st > lastScrollTopFilter + 300) {
                    // downscroll code
                    // Hide filters and header
                    $("#fixed-filters.fixed-filters-on").addClass("fixed-filters-hidden");
                    $("#HeaderGroup").addClass("header-hidden");
                    lastScrollTopFilter = st;
                } else if (lastScrollTopFilter - st > 150) {
                    // on upscroll
                    // if scroll up more than 150px
                    // Show filters
                    $("#fixed-filters.fixed-filters-on").removeClass("fixed-filters-hidden");
                    $("#HeaderGroup").removeClass("header-hidden");
                    lastScrollTopFilter = st;
                }
            }
        });
    }

    // Show the alternate image on hover & Price filter slider
    $(function () {
        setupFilterContainer();
        if (isToggleMenuEnabled()) {
            InitializeHideMenuOnScroll();
        }
        InitializeAlternateImageEvents();
        initializePriceSlider();

        $(".productFilterApply").click(function () {
            closeFilters(".productFilterTitleBox");
        });

        var closeOtherFilters =
            $("#filterlist").attr("data-close-others") === "true" && $("#mobSortFilter:visible").length === 0;

        $(".filter-group-title").click(function () {
            //add class 'child-open' to parent
            $("#innerfiltercontainer").addClass("child-open");
            $(this).attr("aria-selected", "true");

            var tabpanid = $(this).attr("aria-controls"), //find out what tab panel this tab controls
                tabpan = $("#" + tabpanid);
            tabpan.attr("aria-hidden", "false"); // show our panel

            //add class 'open' to relevent filter
            var key = $(this).data("item");
            if (key) {
                var filterContainer = $(".productFilterList[data-item='" + key + "']");
                if (filterContainer.length > 0) {
                    $(filterContainer).addClass("open");
                }
            }
        });
        $(".filter-values .btn-back").click(function () {
            $(".filter-group-title").attr("aria-selected", "false");
            $(".productFilterList").attr("aria-hidden", "true");

            //remove class 'filter-open' to parent
            $("#innerfiltercontainer").removeClass("child-open");

            //add class 'open' from all filters
            $("#innerfiltercontainer .productFilterList").removeClass("open");
        });

        $(".productFilterTitleBox:not(.non-collapsible)").click(function () {
            if (!isToggleDesktopFiltersEnabled() && window.innerWidth >= desktopPixelBreakpoint) return;

            if (closeOtherFilters && $(this).siblings(".productFilterList").css("visibility") !== "hidden") {
                closeFilters(this);
            } else if (!closeOtherFilters && $(this).siblings(".productFilterList").is(":visible")) {
                closeFilters(this);
            } else {
                openFilters(this);
                if (closeOtherFilters) {
                    closeFiltersExcept(".productFilterTitleBox", this);
                }
            }
        });

        if (inSingleBrowseBarMode()) {
            $(".productFilter").hover(
                function () {
                    if (!$(".mobFilterContainer").is(":visible"))
                        openFilters($(this).find(".productFilterTitleBox"), "fade");
                },
                function () {
                    if (!$(".mobFilterContainer").is(":visible"))
                        closeFilters($(this).find(".productFilterTitleBox"), "fade");
                },
            );
        } else {
            // If the filters are closed, set the right class
            $("#filterlist > li").each(function () {
                var v = $(".productFilterList:visible", this).length > 0;

                if (!v) $("> div", this).addClass("FilterClosed");
            });
        }

        var timer;
        $(window).resize(function () {
            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(function () {
                setFilterToggleState();
                timer = null;
            }, 300);
        });

        function setFilterToggleState() {
            if (!isToggleDesktopFiltersEnabled() && window.innerWidth >= desktopPixelBreakpoint) {
                $(".productFilterTitleBox").each(function () {
                    openFilters(this);
                });
            }
        }

        function openFilters(element, effect) {
            // If on mobile and ContractFiltersOnOtherExpansionMobile is set to true
            if (contractFiltersOnOtherExpansionMobile === "true" && window.innerWidth < 1022) {
                $(".productFilterTitleBox").each(function () {
                    if (this !== element) {
                        closeFilters(this);
                    }
                });
            }
            var productFilterList = $(element).siblings(".productFilterList");
            if (!closeOtherFilters) {
                if (effect == "fade") productFilterList.fadeIn(250);
                else
                    productFilterList.slideDown(325, function () {
                        $(".productFilterList").css("overflow", "");
                    });
            }
            $(element).removeClass("FilterClosed");
            $(element).closest("li.productFilter").removeClass("FilterClosed");
            setFilterOpenCloseState(element);
        }

        function closeFilters(element, effect) {
            var productFilterList = $(element).siblings(".productFilterList");
            if (!closeOtherFilters) {
                if (effect == "fade") productFilterList.fadeOut(100);
                else productFilterList.slideUp(325);
            }
            $(element).addClass("FilterClosed");
            $(element).closest("li.productFilter").addClass("FilterClosed");
            setFilterOpenCloseState(element);
        }

        function closeFiltersExcept(element, exclude) {
            $(element).not($(exclude)).closest("li.productFilter").addClass("FilterClosed");
            setFilterOpenCloseState(element);
        }

        var touch = isTouchDevice();
        if (touch) {
            $("#ProductContainer").addClass("touch-device");
        }

        $(".mobFilterContainer").click(function () {
            if ($("#FilterContainer").hasClass("initial")) {
                collapseMobileFilters();
                RemoveNoScroll();
            }
            if ($("#FilterContainer").visible()) {
                CloseMobFilterDropDown(refineAndSortTransitionEnabled);
            } else {
                OpenMobFilterDropDown(refineAndSortTransitionEnabled);
            }
            $("#FilterContainer").removeClass("initial");
        });
        // close mobile dropdown methods
        $("#mobclsfltrs").click(function (e) {
            CloseMobFilterDropDown(refineAndSortTransitionEnabled);
            CloseSlideOutFilters();
            e.stopPropagation();
        });
        $("#mobappfltrs").click(function (e) {
            CloseMobFilterDropDown(refineAndSortTransitionEnabled);
            CloseSlideOutFilters();
            e.stopPropagation();
        });
        $("#mobclrfltrs").click(function (e) {
            resetMobileFilters();
            e.stopPropagation();
        });

        $('[data-action="open-mobile-filters"]').on("click", (e) => {
            OpenSlideOutFilters();
        });

        $('[data-action="close-mobile-filters"]').on("click", (e) => {
            CloseSlideOutFilters();
        });

        $("body").click(function (event) {
            var isMobile = $("#mobSortFilter:visible").length > 0;
            var filterModalOpen = $("#FilterContainer.activeFilter").length > 0;

            if (isMobile) {
                $("#innerfiltercontainer").removeClass("child-open");
                $("#innerfiltercontainer .productFilterList").removeClass("open");

                if (filterModalOpen && !$(event.target).closest("#innerfiltercontainer, .mobFilterContainer").length) {
                    CloseMobFilterDropDown(refineAndSortTransitionEnabled);
                }
            }

            if (
                $(event.target).closest(".MobSortSelector")?.length < 1 &&
                $(".mobile-sort-container .productFilterList")?.visible() &&
                !window.IsDesktopView()
            ) {
                closeFilters($(".mobile-sort-container .productFilterTitleBox"));
            }
        });

        $(".dropprods_Order").on("change", function () {
            var currentSelectedOrderBy = $("option:selected", $(this)).val();
            updateStateOnSortOptionChange(currentSelectedOrderBy);
        });

        $(".MobSortSelector input:radio, .sort-by-in-filters input:radio").on("change", function () {
            var isMobile = $("#mobSortFilter:visible").length > 0;
            var $this = $(this);
            if ($this.is(":checked")) {
                var newViewByVal = getNewViewByValue();
                var currentSelectedOrderBy = $this.attr("value");
                var defaultStateKey = "#dcp=1&dppp=" + newViewByVal + "&OrderBy=" + currentSelectedOrderBy;
                var aPagehref = defaultStateKey.replace(/^#/, "");
                var filterValues = getSelectedFilters();
                if (filterValues != null && filterValues.length > 0) {
                    aPagehref += "&Filter=" + filterValues;
                }
                pushState(filters, "", aPagehref);

                if (closeFiltersOnSort && isMobile) {
                    closeFilters($(".productFilterTitleBox"));
                }

                if (!window.IsDesktopView()) {
                    closeFilters($(".mobile-sort-container .productFilterTitleBox"));
                }
            }
        });

        $(".pppl").click(function (e) {
            e.preventDefault();
            var elem = $(this);
            var inlineView = getViewNumberType() === "inline";
            var pppVal = elem.data("dppp");
            if (inlineView) {
                $(".pppl").each(function () {
                    $(this).removeClass("selected");
                });
                elem.addClass("selected");
                setProductsPerPageValue(pppVal);
                return false;
            }
            setProductsPerPageValue();
            return false;
        });

        bindPaginationEvents();

        var loadInitialListing = true;
        if (window.location.hash) {
            var pageParmsObj = hashService.deserialiseLocation(true);

            if (
                pageParmsObj.dcp !== undefined &&
                pageParmsObj.dppp !== undefined &&
                pageParmsObj.OrderBy !== undefined
            ) {
                dcpVal = pageParmsObj.dcp;
                dpppVal = pageParmsObj.dppp;
                setOrderByDdlValue(pageParmsObj.OrderBy);
                if (pageParmsObj.Filter !== undefined) {
                    if (pageParmsObj.Filter.length > 0) {
                        var selectedFiltersLoadState = pageParmsObj.Filter.split("|");
                        if (selectedFiltersLoadState != null && selectedFiltersLoadState.length > 0) {
                            clearFiltersSelection();
                            setFiltersSelection(selectedFiltersLoadState);
                        }
                    }
                } else {
                    clearFiltersSelection();
                }
                if (isLegacy) {
                    SetVal();
                    setProductsPerPageValue(dpppVal);
                } else {
                    // Pre generate the filters - for the hash
                    buildFiltersObject();
                    getProductsInformation(false);
                }
                isScrollToTopEnable = false;
                loadInitialListing = false;
            }
        }

        if (loadInitialListing) {
            try {
                if (window.parent.dataLayer != null) {
                    var columns = $("#activecolumns").val();
                    var noOfItems = parseInt(getNoOfItemsInGTMPost());
                    if (isNaN(noOfItems)) noOfItems = 25;

                    var prods = [];
                    var i = 0;
                    $("#navlist li").each(function () {
                        if (i < noOfItems) {
                            var itemCode = $(this).attr("li-productid");
                            prods[i] = {
                                variantId: itemCode.toString(),
                                position: (i + 1).toString(),
                            };
                        }
                        i++;
                    });

                    if (window.parent.dataLayer != null)
                        window.parent.dataLayer.push({
                            event: "productListing",
                            itemsPerRow: columns.toString(),
                            listingProducts: prods,
                        });
                }
            } catch (e) {}
        }

        var moreCategories = $("#hdnMoreCategoriesToShow");
        if (moreCategories != null && moreCategories.length > 0) {
            var divViewMore = $("#divViewMore");
            var divViewMoreText = $("#divViewMoreText");
            divViewMore.click(function () {
                try {
                    if ($(this).hasClass("hideCat")) {
                        if ($.browser.msie && parseInt($.browser.version, 10) === 8) {
                            $("ul.ulLast").slideDown("slow", function () {
                                divViewMore.removeClass("hideCat");
                                divViewMoreText.html(divViewMore.data("lesstext"));
                            });
                        } else {
                            $("ul.ulLast").fadeIn().slideDown();
                            divViewMore.removeClass("hideCat");
                            divViewMoreText.html(divViewMore.data("lesstext"));
                        }
                    } else {
                        if ($.browser.msie && parseInt($.browser.version, 10) === 8) {
                            $("ul.ulLast").slideUp("slow");
                            divViewMore.addClass("hideCat");
                            divViewMoreText.html(divViewMore.data("moretext"));
                        } else {
                            $("ul.ulLast")
                                .fadeOut()
                                .slideUp(function () {
                                    divViewMore.addClass("hideCat");
                                    divViewMoreText.html(divViewMore.data("moretext"));
                                });
                        }
                    }
                } catch (e) {}
                return false;
            });
        }

        function isElementInView(element) {
            const bounding = element.getBoundingClientRect();
            return (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.right <= window.innerWidth &&
                bounding.bottom <= window.innerHeight
            );
        }

        var $header = document.querySelector("#HeaderGroup");
        var innerfiltercontainer = $("#innerfiltercontainer");

        function positionFilterInView() {
            innerfiltercontainer.css("position", "fixed").css("top", getHeaderHeight());
            innerfiltercontainer.removeClass("sticky-filter");
            innerfiltercontainerSetRelativeTop();
            $(".showFilterButtonWrapper").css("visibility", "hidden");
        }

        function getinnerfiltercontainerCurrentTop() {
            return innerfiltercontainer.css("top").replace("px", "");
        }
        function innerfiltercontainerSetRelativeTop() {
            var ih = innerfiltercontainer.offset().top - innerfiltercontainer.parent().offset().top;
            if (ih < 0) ih = 0;
            innerfiltercontainer.css("position", "relative").css("top", ih);
            innerfiltercontainer.addClass("sticky-filter");
            fixFooterOverflow();
        }

        function fixFooterOverflow() {
            var filtersAndProductsWrapper = $("#FiltersAndProductsWrapper");
            if (filtersAndProductsWrapper.height() < innerfiltercontainer.height()) return;
            var filterBottom = innerfiltercontainer.offset().top + innerfiltercontainer.height();
            var wrapperBottom = filtersAndProductsWrapper.offset().top + filtersAndProductsWrapper.height();
            if (filterBottom > wrapperBottom) {
                var newTop = getinnerfiltercontainerCurrentTop() - (filterBottom - wrapperBottom);
                innerfiltercontainer.css("top", newTop).css("position", "relative");
            }
        }

        function getHeaderHeight() {
            if (isElementInView($header)) {
                return $header.clientHeight;
            } else {
                return 0;
            }
        }

        function stickyScrollFilter() {
            if (!isStickyMenuEnabled()) return;

            if (!inSingleBrowseBarMode()) {
                //to simplyfy logic
                if (isTouchDevice()) {
                    return;
                }

                var buttonText = $("#filterlist").attr("data-buttonText") || "Show Filters";

                var filterContainer = $("#FilterContainer");
                var showFilterButtonDiv = $(
                    '<div  id="showFilterButtonDiv">' +
                        '<div class="showFilterButtonWrapper" style="visibility:hidden">' +
                        '<button type="button" class="btn btn-default showFilterButton" > ' +
                        buttonText +
                        "</button>" +
                        "</div></div>",
                );
                innerfiltercontainer.append(showFilterButtonDiv);

                $("#showFilterButtonDiv .showFilterButton")
                    .off("click")
                    .on("click", function () {
                        positionFilterInView();
                    });

                var lastScrollTop = 0;
                var filterScrollHandler = function () {
                    var minimumButtonVisibleHeight = 50;
                    var maximumButtonVisibleHeight = 100;
                    var st = $(this).scrollTop();
                    var showFilterButtonDiv = $("#showFilterButtonDiv");
                    var filterHeight = $("#innerfiltercontainer").outerHeight();
                    var productListContainer = $("#productlistcontainer").outerHeight();
                    var headerPromo = document.querySelectorAll(".sitewide-banner, .header-promo");

                    if (st > lastScrollTop && productListContainer > filterHeight) {
                        // downscroll
                        var showFilterButtonDivtop = showFilterButtonDiv[0].getBoundingClientRect().top;
                        var visibleHeight = showFilterButtonDivtop - getHeaderHeight();

                        if (visibleHeight < minimumButtonVisibleHeight) {
                            if (
                                showFilterButtonDiv[0].getBoundingClientRect().bottom <
                                $("#FiltersAndProductsWrapper")[0].getBoundingClientRect().bottom
                            ) {
                                showFilterButtonDiv.find(".showFilterButtonWrapper").css("visibility", "visible");
                                //showFilterButtonDiv.find('.showFilterButtonWrapper').removeClass('hidden');
                                var ih = showFilterButtonDiv.position().top - getHeaderHeight();
                                innerfiltercontainer.css("top", ih * -1).css("position", "fixed");
                                innerfiltercontainer.addClass("sticky-filter");
                            } else {
                                showFilterButtonDiv.find(".showFilterButtonWrapper").css("visibility", "hidden");
                                innerfiltercontainer.removeClass("sticky-filter");
                            }
                        } else {
                            innerfiltercontainerSetRelativeTop();
                        }
                    } else {
                        // upscroll
                        var showFilterButtonDivtop = showFilterButtonDiv[0].getBoundingClientRect().top;
                        var visibleHeight = showFilterButtonDivtop - getHeaderHeight();
                        if (visibleHeight > maximumButtonVisibleHeight) {
                            showFilterButtonDiv.find(".showFilterButtonWrapper").css("visibility", "hidden");
                            innerfiltercontainer.removeClass("sticky-filter");
                            if (isElementInView($header) && headerPromo[0] != null) {
                                if (window.scrollY > 0) {
                                    innerfiltercontainer.css("padding-top", headerPromo[0].clientHeight);
                                } else {
                                    innerfiltercontainer.css("padding-top", "");
                                }
                            }
                            //showFilterButtonDiv.find('.showFilterButtonWrapper').addClass('hidden');
                        }

                        var innerTop = innerfiltercontainer[0].getBoundingClientRect().top;

                        if (innerTop > 0 && innerfiltercontainer.position().top > 0) {
                            innerfiltercontainer.css("position", "fixed").css("top", getHeaderHeight());
                        }

                        if (innerfiltercontainer.css("position") == "fixed") {
                            innerfiltercontainerSetRelativeTop();
                        }
                    }
                    lastScrollTop = st;
                };

                $(window).scroll(filterScrollHandler);

                var resizeMenuW = function () {
                    innerfiltercontainerSetRelativeTop();
                    setFilterToggleState();

                    if (isFullWidthStickyMenuEnabled()) {
                        var w = filterContainer[0].getBoundingClientRect().width;
                        innerfiltercontainer.css("width", w);
                        return;
                    }

                    innerfiltercontainer.css("width", "auto");
                    var w = innerfiltercontainer[0].getBoundingClientRect().width;

                    if (w > 0) {
                        innerfiltercontainer.css("width", w);
                    }
                };
                $(window).resize(resizeMenuW);
                resizeMenuW();

                positionFilterInView();
            }
        }

        InitializeGTMEvents();
        InitializeQuickBuyAndWishListEvents();
        InitializeStickyFilterBar();
        SetUpTwoColumnFilterDisplay();
        stickyScrollFilter();
    });

    function RemoveNoScroll() {
        $("html, body").removeClass("no-scroll");
        if ($(document).height() > scrollHeight) {
            $("html, body").scrollTop(scrollHeight);
        } else {
            $("html, body").scrollTop($(document).height() - scrollHeight);
        }
    }

    function AddNoScroll() {
        scrollHeight = $(document).scrollTop();
        $("html, body").addClass("no-scroll");
    }
    function CloseMobFilterDropDown(animate) {
        CloseMobDropdown("#FilterContainer", ".mobFilterContainer", animate);
        collapseMobileFilters();
        RemoveNoScroll();
    }
    function OpenMobFilterDropDown(animate) {
        AddNoScroll();
        OpenMobDropdown("#FilterContainer", ".mobFilterContainer", animate);
    }
    function CloseMobDropdown(selector, parentSelector, animate) {
        var element = $(selector);

        var isMobile = $("#mobSortFilter:visible").length > 0;

        if (isMobile) {
            if (!animate) {
                if (refineAndSortTransitionEnabled) {
                    element.addClass("MobClosed").css("height", "0");
                }
                $("#FilterContainer").removeClass("activeFilter");
                return;
            }
            element.animate(
                { height: 0 },
                {
                    duration: 325,
                    complete: function () {
                        $(this).addClass("MobClosed").css("height", "0");
                    },
                },
            );
        } else {
            element.css("height", "auto");
        }
    }

    function OpenMobDropdown(selector, parentSelector, animate) {
        var element = $(selector);
        element.removeClass("MobClosed");

        var targetHeight = $(window).height() - $(".HeaderWrap").height();

        if (!animate) {
            if (refineAndSortTransitionEnabled) {
                element.css("height", targetHeight + "px");
            }
            $("#FilterContainer").addClass("activeFilter");
            return;
        }
        element.css("height", "0");
        element.animate(
            { height: targetHeight },
            {
                duration: 325,
            },
        );
        $("#innerfiltercontainer").scrollTop(0);
    }

    const OpenSlideOutFilters = () => {
        $("#FilterContainer").addClass("filters-open");
        setTimeout(() => {
            AddNoScroll();
        }, 300); //setTimeout used here to prevent the page appearing like it scrolls to the top before showing the filters.
    };

    const CloseSlideOutFilters = () => {
        var filterContainer = $("#FilterContainer");

        if (!filterContainer.hasClass("filters-open")) return;

        filterContainer.removeClass("filters-open");
        RemoveNoScroll();
    };

    function InitializeSegmentEvents() {
        // Segment function that fires whenever an item is clicked in the browse list
        $("#ProductContainer").on("click", "#navlist li", function (event) {
            try {
                if (segment.ProductViewedData) {
                    var isFullPrice = $(this).attr("li-isfullprice");
                    var product = {
                        brand: $(this).attr("li-brand"),
                        category: $(this).attr("li-category"),
                        category_id: $(this).attr("li-categoryid"),
                        color: $(this).attr("li-colour"),
                        currency: null,
                        image_url: $(this).attr("li-imageurl"),
                        is_full_price: isFullPrice != null ? isFullPrice.toLowerCase() : false,
                        name: $(this).attr("li-name"),
                        position: $(this).attr("li-position"),
                        price: $(this).attr("li-price"),
                        price_gbp: $(this).attr("li-priceinbaseunit"),
                        product_id: $(this).attr("li-productidoriginal"),
                        quantity: null,
                        sku: $(this).attr("li-sku"),
                        sub_category: $(this).attr("li-subcategory"),
                        variant: $(this).attr("li-variantoriginal"),
                        activity: $(this).attr("li-activity"),
                        activity_group: $(this).attr("li-activitygroup"),
                        budget_curve: $(this).attr("li-budgetcurve"),
                    };

                    var eventData = new segment.ProductViewedData(product);
                    segment.trackProductClicked(eventData);
                }
                algoliaUtil.saveSearchAttributes(this, $(this).attr("li-productid"));
            } catch (e) {
                console.log(e);
            }
        });
    }

    function InitializeGTMEvents() {
        // gtm function that fires whenever an item is clicked in the browse list
        $("#navlist li").click(function (event) {
            try {
                var itemCode = $(this).attr("li-productid");
                var columns = $("#activecolumns").val();
                var itemIndex = $(this).index() + 1;

                if (window.parent.dataLayer != null) {
                    window.parent.dataLayer.push({
                        event: "listClicked",
                        variantId: itemCode.toString(),
                        itemsPerRow: columns.toString(),
                        position: itemIndex.toString(),
                    });
                }

                var product = {
                    VariantId: itemCode.toString(),
                    ProductSequenceNumber: $(this).attr("li-seq"),
                    ProductName: $(this).attr("li-name"),
                    ProductSellingPrice: $(this).attr("li-price"),
                    ProductBrand: $(this).attr("li-brand"),
                    ProductVariant: $(this).attr("li-variant"),
                    ProductCategory: $("#divBrowseCategory").attr("data-category"),
                    ProductList: $("#divBrowseCategory").attr("data-list"),
                    ProductTaxonomy: $(this).attr("li-taxonomy"),
                    ProductPosition: itemIndex,
                };

                var target = $(event.target);
                if (!(target.hasClass("hotspotwishlist") || target.parent().hasClass("hotspotwishlist"))) {
                    sendEEBrowseEvent(product);
                }
                if (target.hasClass("hotspotquickbuy") || target.parent().hasClass("hotspotquickbuy")) {
                    sendEEProductDetailEvent(product);
                }
                if (target.hasClass("hotspotwishlist") || target.parents(".hotspotwishlist").length > 0) {
                    sendGtmWishlistEvent(product);
                }
            } catch (e) {}
        });
    }

    function getWishlistItemDetails(product) {
        if (!product) return;

        var ee = $("#divBrowseSettings").attr("data-ee");
        if (ee === "true" && window.parent.dataLayer != null) {
            return {
                id: product.VariantId,
                name: product.ProductName,
                price: product.ProductSellingPrice,
                brand: product.ProductBrand,
                // the variant appears to be a substring based on '/'
                variant: product.ProductVariant,
                category: ReplaceCategoryCrumbNestedChar(product.ProductCategory),
                taxonomy: product.ProductTaxonomy,
                quantity: 1,
            };
        }
    }

    function sendGtmWishlistEvent(product) {
        if (!product) return;

        sendGtmCustomEvent({
            event: "wishlistHeartClick",
            ecommerce: {
                currencyCode: window._currencyFormatter.ActiveCurrency,
                wishlistItems: [getWishlistItemDetails(product)],
            },
        });
    }

    function sendGtmCustomEvent(event) {
        try {
            if (window.parent.dataLayer != null) {
                window.parent.dataLayer.push(event);
            }
        } catch (e) {
            console.log(e);
        }
    }

    function sendEEBrowseEvent(product) {
        if (!product) return;
        var ee = $("#divBrowseSettings").attr("data-ee");
        if (ee === "true" && window.parent.dataLayer != null) {
            var id = product.VariantId.substr(0, 8);
            if (product.ProductSequenceNumber) {
                id += "-" + product.ProductSequenceNumber;
            }
            var category = ReplaceCategoryCrumbNestedChar(product.ProductCategory);

            var products = {
                actionField: {
                    list: product.ProductList,
                },
                products: [
                    {
                        id: id,
                        name: product.ProductName,
                        price: product.ProductSellingPrice,
                        brand: product.ProductBrand,
                        category: category,
                        position: product.ProductPosition,
                        variant: product.ProductVariant,
                        dimension5: product.VariantId.substr(0, 8),
                        dimension6: window._currencyFormatter.ActiveCurrency,
                        dimension10: $("#activecolumns").val().toString(),
                    },
                ],
            };

            var data = {
                event: "productClick",
                ecommerce: {
                    currencyCode: window._currencyFormatter.ActiveCurrency,
                },
            };

            data.ecommerce["click"] = products;
            window.parent.dataLayer.push(data);
        }
    }

    function sendEEProductDetailEvent(product) {
        if (!product) return;
        var ee = $("#divBrowseSettings").attr("data-ee");
        if (ee === "true" && window.parent.dataLayer != null) {
            var id = product.VariantId.substr(0, 8);
            if (product.ProductSequenceNumber) {
                id += "-" + product.ProductSequenceNumber;
            }
            var category = ReplaceCategoryCrumbNestedChar(product.ProductCategory);

            var products = {
                products: [
                    {
                        id: id,
                        name: product.ProductName,
                        price: product.ProductSellingPrice,
                        brand: product.ProductBrand,
                        category: category,
                        position: product.ProductPosition,
                        variant: product.ProductVariant,
                        dimension5: product.VariantId.substr(0, 8),
                        dimension6: window._currencyFormatter.ActiveCurrency,
                    },
                ],
            };

            var data = {
                event: "productDetail",
                ecommerce: {
                    currencyCode: window._currencyFormatter.ActiveCurrency,
                },
            };

            data.ecommerce["detail"] = products;
            window.parent.dataLayer.push(data);
        }
    }

    function ReplaceCategoryCrumbNestedChar(crumb) {
        if (!crumb) {
            return crumb;
        }

        var parts = crumb.replace("/", "&").split(">");
        return parts.slice(0, 5).join("/");
    }

    function InitializeStickyFilterBar() {
        var isMobile = $("#mobSortFilter:visible").length > 0;
        if ((!isMobile && !inSingleBrowseBarMode()) || !isStickyMenuEnabled()) return;

        var contentAreaTop = $(".HeaderTopSpacer").height();
        var stickPosition = $("#TopPaginationWrapper").offset().top - contentAreaTop;

        function setStickyNavClass() {
            if ($(window).scrollTop() > stickPosition) {
                var contentAreaWidth = $(".s-maincontent-container").width();
                var filterBarHeight = $("#TopPaginationWrapper").height();
                $("#TopPaginationWrapper")
                    .addClass("sticky")
                    .css("top", contentAreaTop)
                    .css("width", contentAreaWidth + "px");
                $("#FilterContainer")
                    .addClass("sticky")
                    .css("top", contentAreaTop + filterBarHeight)
                    .css("width", contentAreaWidth + "px");
            } else {
                $("#TopPaginationWrapper").removeClass("sticky").css("top", "").css("width", "");
                $("#FilterContainer").removeClass("sticky").css("top", "").css("width", "");
            }
        }

        var lastScrollTopFilter = 0;
        function setMobileFilterBarClass() {
            var scrollTop = $(window).scrollTop();
            if (scrollTop <= stickPosition) {
                // turn to sticky
                $("#TopPaginationWrapper").addClass("staticFilterBar").removeClass("activeFilterBar");
            }

            if (scrollTop > lastScrollTopFilter) {
                // downscroll code
                // Hide filters and header
                if (lastScrollTopFilter > 0) {
                    $("#TopPaginationWrapper").removeClass("activeFilterBar");
                }
                lastScrollTopFilter = scrollTop;
            } else if (lastScrollTopFilter - scrollTop > 150) {
                // on upscroll
                // if scroll up more than 150px
                // Show filters
                $("#TopPaginationWrapper").addClass("activeFilterBar");
                lastScrollTopFilter = scrollTop;
            }
        }

        $(window).scroll(setStickyNavClass);
        setStickyNavClass();
        if (isMobile) {
            $(window).scroll(setMobileFilterBarClass);
            setMobileFilterBarClass();
        }
    }

    function SetUpTwoColumnFilterDisplay() {
        if (!inSingleBrowseBarMode()) return;

        $(".productFilterList").each(function () {
            var $this = $(this);
            var filterListItems = $(this).find(".FilterListItem");
            if (filterListItems.length >= 10) {
                $this.addClass("productFilterListTwoColumns");
                var lowerColumnSplitPoint = Math.ceil(filterListItems.length / 2);
                var upperColumnSplitPoint = lowerColumnSplitPoint - 1;
                $this
                    .find(".FilterListItem:lt(" + lowerColumnSplitPoint + ")")
                    .wrapAll('<div class="FilterListColumn"/>');
                $this
                    .find(".FilterListItem:gt(" + upperColumnSplitPoint + ")")
                    .wrapAll('<div class="FilterListColumn"/>');
            }
        });
    }

    function scrollToTop() {
        try {
            if (isScrollToTopEnable) {
                var scrollToPosition;
                if (inSingleBrowseBarMode()) {
                    scrollToPosition = 0;
                } else if (productListingScrollToProductList) {
                    var bodyPos = $("body")[0].getBoundingClientRect();
                    var filterPos = $(".FilterProductsTable")[0].getBoundingClientRect();
                    scrollToPosition = filterPos.top - bodyPos.top;

                    $("#HeaderGroup, .HeaderTopPromo").each(function () {
                        scrollToPosition -= $(this).height();
                    });
                } else {
                    scrollToPosition = $(".ContentWrapper").scrollTop();
                    var headerGroup = $("#HeaderGroup");
                    if (headerGroup.length != 0) scrollToPosition = scrollToPosition - headerGroup.height();
                }

                var currentScrollPos = $(document).scrollTop();
                if (currentScrollPos > scrollToPosition) {
                    $("html, body").scrollTop(0);
                }
            } else {
                isScrollToTopEnable = true;
            }
        } catch (e) {
            console.log(e);
        }
    }

    function InitializeAlternateImageEvents() {
        $(".ProductImageList").hover(
            function () {
                if ($(this).children().length <= 1) {
                    return;
                }
                var $this = $(this);
                $this.data("okToSwitch", "true");
                var timeout = setTimeout(function () {
                    if ($this.data("okToSwitch") == "true") {
                        $this.find(".AlternateImageContainerDiv").fadeIn(200);
                        $this.data("okToSwitch", "done");
                    }
                }, 500);
                $this.data("timeout", timeout);
            },
            function () {
                if ($(this).children().length <= 1) {
                    return;
                }
                var itimeout = $(this).data("timeout");
                if (itimeout != null) {
                    clearTimeout(itimeout);
                }
                if ($(this).data("okToSwitch") == "done") {
                    $(this).find(".AlternateImageContainerDiv").fadeOut(200);
                } else {
                    $(this).data("okToSwitch", "false");
                }
            },
        );
    }

    function setOrderByDdlValue(value) {
        if (isSortOptionsTemplateEnabled()) {
            $(".ddlSortOptions .ddlSortOption").attr("data-selected", false);
            var selected = $(".ddlSortOptions .ddlSortOption[data-optionvalue='" + value.toLowerCase() + "']");
            selected.attr("data-selected", true);
            $(".sortOptionsContainer .selectedOption").text(selected.data("optionlabel"));
        } else {
            $(".dropprods_Order").find("option:selected").removeAttr("selected");
            $(".dropprods_Order option[value='" + value.toLowerCase() + "']").prop("selected", true);
        }

        var filters = $(".MobSortSelector, .sort-by-in-filters");

        filters.find("input:radio").removeAttr("checked");
        filters.find("input:radio[value='" + value.toLowerCase() + "']").prop("checked", true);

        applySortFilterText();
    }

    function checkVariableExists(gVariable) {
        if (typeof gVariable !== "undefined") {
            return true;
        } else {
            return false;
        }
    }

    function initializePriceSlider() {
        if (window.priceFilterSliderEnabled) {
            var roundTo = parseInt($("#hdnPriceSliderRoundTo").val());
            if (!roundTo) roundTo = 5;
            $("#slider-range").slider({
                range: true,
                min: toLowestNumber(parseInt($("#hdnLowVal").val()), roundTo), //Use floor to round down to lowest x.
                max: toHighestNumber(parseInt($("#hdnHighVal").val()), roundTo), //use ceiling to round up to highest x.
                step:
                    roundTo <= 5
                        ? toNearestNumber(
                              (parseInt($("#hdnHighVal").val()) - parseInt($("#hdnLowVal").val())) / 10,
                              roundTo,
                          )
                        : roundTo, //Only ever increment in multiples of x * 2 (or what is set).
                values: [0, 0],
                slide: function (event, ui) {
                    setPriceFilterDisplayValue(ui.values[0], ui.values[1]);
                },
                stop: function (event, ui) {
                    //Clear this filter if the price has set back to the original min & max
                    var filterActive =
                        ui.values[0] == parseInt($("#hdnLowVal").val()) &&
                        ui.values[1] == parseInt($("#hdnHighVal").val())
                            ? "false"
                            : "true";
                    resetPriceFilterManualEntry();
                    SetVal(null, "APRI", ui.values[0] + "-" + ui.values[1], "", filterActive);
                },
            });
        } else {
            $("#PriceFilterTextEntryApply").click(function () {
                var fromTextValue = $.trim($("#PriceFilterTextEntryMin").val());
                var toTextValue = $.trim($("#PriceFilterTextEntryMax").val());

                if (fromTextValue != null && toTextValue != null) {
                    fromTextValue = fromTextValue.replace(/[^a-zA-Z0-9]/g, "");
                    toTextValue = toTextValue.replace(/[^a-zA-Z0-9]/g, "");
                }

                if (fromTextValue == "") fromTextValue = $("#hdnLowVal").val();

                if (toTextValue == "") toTextValue = $("#hdnHighVal").val();

                var fromValue = parseInt(fromTextValue);
                var toValue = parseInt(toTextValue);

                if (isNaN(fromValue) || isNaN(toValue)) {
                    $("#PriceFilterTextEntryError").show();
                    return;
                }
                $("#PriceFilterTextEntryError").hide();
                if (fromValue > toValue) {
                    var tempValue = fromValue;
                    fromValue = toValue;
                    toValue = tempValue;
                }
                resetFilterCheckboxesForKey("APRI");
                SetVal(null, "APRI", fromValue + "-" + toValue, "", "true");
            });

            $("#PriceFilterTextEntryMin, #PriceFilterTextEntryMax").keypress(function (e) {
                var key = e.which;
                if (key == 13) {
                    // the enter key code
                    $("#PriceFilterTextEntryApply").click();
                    return false;
                }
                return true;
            });
        }
        setPriceSlider();
    }

    function setPriceSlider() {
        //Price filter slider
        if (window.priceFilterSliderEnabled) {
            var roundTo = parseInt($("#hdnPriceSliderRoundTo").val());
            if (!roundTo) roundTo = 5;
            $("#slider-range").slider("values", 0, toLowestNumber(parseInt($("#hdnMinPrice").val()), roundTo));
            $("#slider-range").slider("values", 1, toHighestNumber(parseInt($("#hdnMaxPrice").val()), roundTo));

            if ($("#slider-range").slider("values").length != 0) {
                setPriceFilterDisplayValue(
                    $("#slider-range").slider("values", 0),
                    $("#slider-range").slider("values", 1),
                );
            }
        } else {
            $("#PriceFilterTextEntryError").hide();
            $("#PriceFilterTextEntryMin").val($("#hdnMinPrice").val());
            $("#PriceFilterTextEntryMax").val($("#hdnMaxPrice").val());
        }
    }

    function setPriceFilterDisplayValue(fromValue, toValue) {
        var separater = $("#filterlist").data("pricevalueseparater");
        $("#amount, .mobAppliedFilters[data-item='apri']").text(
            window._currencyFormatter.FormatCurrencyValueZeroDP(fromValue) +
                " " +
                separater +
                " " +
                window._currencyFormatter.FormatCurrencyValueZeroDP(toValue),
        );
    }

    function resetPriceSlider() {
        setHiddenPriceSliderValue("");
        if (window.priceFilterSliderEnabled) {
            $("#hdnMinPrice").val($("#hdnLowVal").val());
            $("#hdnMaxPrice").val($("#hdnHighVal").val());
        } else {
            $("#hdnMinPrice").val("");
            $("#hdnMaxPrice").val("");
        }
        resetPriceFilterManualEntry();
        setPriceSlider();
    }

    function resetPriceFilterManualEntry() {
        $("#PriceFilterTextEntryMin").val("");
        $("#PriceFilterTextEntryMax").val("");
    }

    function toLowestNumber(number, to) {
        if (!to) to = 5;
        return to * Math.floor(Math.abs(number / to));
    }

    function toHighestNumber(number, to) {
        if (!to) to = 5;
        return to * Math.ceil(Math.abs(number / to));
    }

    function toNearestNumber(number, to) {
        if (!to) to = 5;
        return to * Math.round(number / to);
    }

    // BrowseV3 Functions
    function SetVal(ev, name, key, id, active) {
        try {
            if (!isLegacy && ev) {
                ev.preventDefault();
            }

            var isFilterSelection = false,
                clearFilters = false;
            if (name != null) {
                dcpVal = 1;
                if (name == "CLEAR" && key != null) {
                    // Add the clear to state
                    filters = {};
                    if (key == "###") {
                        isFilterSelection = true;
                        clearFilters = true;
                        clearFiltersSelection();
                        if (showFilterSectionClear) {
                            removeClearAllButtons();
                        }
                    } else {
                        clearFiltersSelection(key);
                    }
                    setFirstFilterSelected();
                    buildFiltersObject();
                    constructAndSetFilterStateKey();
                } else if (key != null) {
                    isFilterSelection = true;
                    hasUserInteractedWithFilters = true;
                    if (name == "APRI" && active != null) {
                        setHiddenPriceSliderValue(active.toLowerCase() == "false" ? "" : name + "^" + key);
                    } else {
                        if (name == "APRI" && !window.priceFilterSliderEnabled) {
                            setHiddenPriceSliderValue("");
                        }
                        setFilterStatusVal(id);
                        setFirstFilterSelected(name, id);
                    }
                    //Use set timeout to give the UI a chance to render
                    buildFiltersObject();
                    setTimeout(constructAndSetFilterStateKey(), 0);
                }
            }

            if (!isFilterSelection) {
                getProductsInformation(clearFilters);
            }
        } catch (e) {
            console.log(e);
        }
        return false;
    }

    function setHiddenPriceSliderValue(value) {
        $("#hdnPriceSliderValue").val(value);
    }

    function showSectionClearButton(id) {
        var $id = $(id);
        var closestSection = $id.closest(".productFilter");
        var closestClearAllButton = closestSection.find(".clearfilters")[0];
        var sectionFilterItems = $id.closest(".productFilterList").find(".FilterListItem > .FilterAnchor");
        var itemsSelected = 0;
        sectionFilterItems.each(function () {
            if ($(this).children().hasClass("SelectedFilter")) {
                itemsSelected++;
            }
        });
        itemsSelected
            ? ($(closestClearAllButton).addClass("visible"), $(closestSection).addClass("filtersSet"))
            : ($(closestClearAllButton).removeClass("visible"), $(closestSection).removeClass("filtersSet"));
    }

    function removeClearAllButtons() {
        $(".productFilter > .clearfilters.visible").removeClass("visible");
        $(".productFilter.filtersSet").removeClass("filtersSet");
    }

    function setFilterStatusVal(id) {
        var filterSelectionId = $(id).children("span");
        filterSelectionId.toggleClass("SelectedFilter SelectableFilter").attr("aria-checked", "false");

        if (showFilterSectionClear) {
            showSectionClearButton(id);
        }
    }

    function setFirstFilterSelected(name, id) {
        var $filterGroup = $('*[data-item^="' + name + '"].FirstSelectedFilter');
        if (name) {
            // Ensure only 1 item per filter group
            // is the first selected one.
            var filterSelectionId = $(id).children("span");
            if (filterSelectionId.hasClass("SelectedFilter")) {
                // If it's selected, only set it as the first one if required
                if ($filterGroup.length === 0) {
                    filterSelectionId.addClass("FirstSelectedFilter").attr("data-priority", -1);
                }
            } else {
                // Otherwise, deselect and find the next filter to be the first
                filterSelectionId.removeClass("FirstSelectedFilter").attr("data-priority", 0);
                $('*[data-item^="' + name + '"].SelectedFilter:first')
                    .addClass("FirstSelectedFilter")
                    .attr("data-priority", -1);
            }
        } else {
            // Clear them
            $("span.FirstSelectedFilter").each(function () {
                $(this).removeClass("FirstSelectedFilter");
            });
        }
    }

    function clearFiltersSelection(filterKey, force) {
        if (!force && !isEmpty(filters)) return;
        if (filterKey == null) {
            $("#filterlist .SelectedFilter").each(function () {
                resetFilterCheckBox(this);
            });
            resetPriceSlider();
        } else if (filterKey == "APRI") {
            resetPriceSlider();
            resetFilterCheckboxesForKey(filterKey);
        } else {
            resetFilterCheckboxesForKey(filterKey);
        }
    }

    function isEmpty(object) {
        for (var key in object) {
            if (object.hasOwnProperty(key)) return false;
        }
        return true;
    }

    function resetFilterCheckboxesForKey(key) {
        $("#filterlist .FilterListItem." + key + " .SelectedFilter").each(function () {
            resetFilterCheckBox(this);
        });

        var clearedFilterSection = $("#filterlist .FilterListItem." + key + " .SelectableFilter");
        if (showFilterSectionClear) {
            showSectionClearButton(clearedFilterSection);
        }
    }

    function resetFilterCheckBox(filterCheckbox) {
        $(filterCheckbox).addClass("SelectableFilter").removeClass("SelectedFilter").attr("aria-checked", "false");
    }

    function setFiltersSelection(filtersArray) {
        contentWrapperEle.addClass("ProductsFiltered");
        $(".mobFilterAppIcon").addClass("glyphicon glyphicon-ok-sign").show();
        var selectableFiltersList = $(".SelectableFilter");
        $(".mobAppliedFilters").html("");
        $(".productFilter").removeClass("FilteringApplied");
        if (selectableFiltersList != null) {
            // New array
            var filtersArrayMap = [];
            var displayItemParts = [];
            if (filtersArray) {
                $(filtersArray).each(function (i, item) {
                    var dataItem = item;
                    if (dataItem == "none") {
                        contentWrapperEle.removeClass("ProductsFiltered");
                        $(".mobFilterAppIcon").removeClass("glyphicon glyphicon-ok-sign").hide();
                    }
                    var dataItemParts = dataItem.split("^");
                    displayItemParts = item.split("^");
                    if (displayItemParts.length > 1) {
                        if (dataItemParts[0] == "apri") {
                            if (window.priceFilterSliderEnabled) {
                                //Price slider
                                setPriceFilterDisplayValue(
                                    $("#slider-range").slider("values", 0),
                                    $("#slider-range").slider("values", 1),
                                );
                            } else {
                                //Price manual entry
                                var match = displayItemParts[1].match(/(\d+)-(\d+)/);
                                if (match) {
                                    setPriceFilterDisplayValue(parseFloat(match[1]), parseFloat(match[2]));
                                }
                            }
                        }
                        $(".mobAppliedFilters[data-item='" + dataItemParts[0] + "']")
                            .closest(".productFilter")
                            .addClass("FilteringApplied");
                    }
                    if (dataItemParts.length > 1) {
                        var dataItemValueParts = dataItemParts[1].split(",");
                        $(dataItemValueParts).each(function (index, val) {
                            filtersArrayMap.push(dataItemParts[0] + "^" + val);
                        });
                    }
                });
            } else {
                if (!isEmpty(filters)) {
                    for (var property in filters) {
                        if (filters.hasOwnProperty(property)) {
                            if (property === "APRI") {
                                if (window.priceFilterSliderEnabled) {
                                    // Price slider
                                    setPriceFilterDisplayValue(
                                        $("#slider-range").slider("values", 0),
                                        $("#slider-range").slider("values", 1),
                                    );
                                } else {
                                    //Price manual entry
                                    if (displayItemParts.length > 1) {
                                        var match = displayItemParts[1].match(/(\d+)-(\d+)/);
                                        if (match) {
                                            setPriceFilterDisplayValue(parseFloat(match[1]), parseFloat(match[2]));
                                        }
                                    }
                                }
                            }
                            $(".mobAppliedFilters[data-item='" + property + "']")
                                .closest(".productFilter")
                                .addClass("FilteringApplied");
                            $.each(filters[property].keys, function (i, key) {
                                filtersArrayMap.push(property + "^" + key);
                            });
                        }
                    }
                } else {
                    contentWrapperEle.removeClass("ProductsFiltered");
                    $(".mobFilterAppIcon").removeClass("glyphicon glyphicon-ok-sign").hide();
                }
            }

            var filterSelectedValueLists = {};
            $(selectableFiltersList).each(function () {
                var dataItem = $(this).attr("data-item");
                if (dataItem != null && dataItem.length > 0) {
                    if ($.inArray(dataItem, filtersArrayMap) != -1) {
                        var filterKey = dataItem.split("^")[0];
                        var $this = $(this);
                        $this.addClass("SelectedFilter").removeClass("SelectableFilter").attr("aria-checked", "true");
                        if (filterSelectedValueLists[filterKey] == null) filterSelectedValueLists[filterKey] = [];
                        filterSelectedValueLists[filterKey].push($this.find(".FilterName").text());
                    }
                }
            });

            for (var filterSelectedKey in filterSelectedValueLists) {
                if (filterSelectedKey.toLowerCase() == "apri" && window.priceFilterSliderEnabled) continue;
                var selectedFilterHtml = "";
                $.each(filterSelectedValueLists[filterSelectedKey], function (index, value) {
                    selectedFilterHtml += "<span class='selectedFilter'>" + $.trim(value) + "</span>";
                    if (index + 1 < filterSelectedValueLists[filterSelectedKey].length)
                        selectedFilterHtml += "<span class='seperator'></span>";
                });
                $(".mobAppliedFilters[data-item='" + filterSelectedKey.toLowerCase() + "']").html(selectedFilterHtml);
            }

            var priceFilterArray = $.grep(filtersArrayMap, function (val) {
                return val.indexOf("APRI") != -1;
            });

            if (priceFilterArray != null && priceFilterArray.length > 0) {
                setHiddenPriceSliderValue(priceFilterArray[0]);
                var priceValSplit = priceFilterArray[0].split("^");

                if (priceValSplit != null && priceValSplit.length > 1) {
                    var valueSplit = priceValSplit[1].split("-");
                    if (valueSplit != null && valueSplit.length > 1) {
                        $("#hdnMinPrice").val(valueSplit[0]);
                        $("#hdnMaxPrice").val(valueSplit[1]);
                        setPriceSlider();
                    }
                }
            } else {
                setHiddenPriceSliderValue("");
            }
        }

        applySortFilterText();
    }

    function applySortFilterText() {
        $(".MobSortSelector input:radio")
            .filter(":checked")
            .each(function () {
                var $this = $(this);
                var selectedSortValueName = $this.data("optionname");
                var parent = $this.closest(".MobSortSelector");
                parent.find(".mobAppliedFilters").text(selectedSortValueName);
                parent.find(".MobSortSelectionCheckbox").removeClass("SelectedFilter");
                $this.closest(".FilterListItem").find(".MobSortSelectionCheckbox").addClass("SelectedFilter");
            });
    }

    function applyMobileFiltersCount() {
        var filterContainer = $(".FilterProductsTable");
        var mobilefilterButton = $("#filterByMob");
        var filtersToggleContainer = $(".productFiltersToggleContainer");
        var filtersCount = $(filterContainer).find($("#filterlist .FilterListItem .SelectedFilter")).length;
        var totalFilterCount = $(filterContainer).find($(".mobappfltrsNo"));
        var toggledOffFiltersCount = $(filtersToggleContainer).find($(".filterCount"));
        var $filtersNo = $(mobilefilterButton)?.find($(".mobappfltrsNo"));
        var filterHeaderNumber = $(filterContainer).find($("#FiltersHeader .mobappfltrsNo"));
        // Show total filter count ...
        if (selectedFilterCount) {
            if (filtersCount > 0) {
                if (removeBracketsFromAppliedFilterCounts) {
                    totalFilterCount.text(filtersCount);
                    $filtersNo.text(filtersCount);
                    toggledOffFiltersCount.text(filtersCount);
                    $(mobilefilterButton).addClass("activeFilters");
                    $(filterHeaderNumber).addClass("activeFilters");
                    filtersToggleContainer.addClass("activeFilters");
                } else {
                    totalFilterCount.text(" (" + filtersCount + ")");
                    $filtersNo.text(" (" + filtersCount + ")");
                    toggledOffFiltersCount.text(" (" + filtersCount + ")");
                    $(mobilefilterButton).addClass("activeFilters");
                    $(filterHeaderNumber).addClass("activeFilters");
                    filtersToggleContainer.addClass("activeFilters");
                }
            } else {
                totalFilterCount.text("");
                $filtersNo.text("");
                toggledOffFiltersCount.text("");
                $(mobilefilterButton).removeClass("activeFilters");
                $(filterHeaderNumber).removeClass("activeFilters");
                filtersToggleContainer.removeClass("activeFilters");
            }
        }
        // Everything else
        if (!selectedFilterCount) {
            if ($filtersNo.length > 0) {
                if (filtersCount > 0) {
                    if (removeBracketsFromAppliedFilterCounts) {
                        $filtersNo.text(filtersCount);
                    } else {
                        $filtersNo.text(" (" + filtersCount + ")");
                    }
                    $(mobilefilterButton).addClass("activeFilters");
                } else {
                    $filtersNo.text("");
                    $(mobilefilterButton).removeClass("activeFilters");
                }
            }
        }
    }

    function displayMobileFiltersCount(productsCount) {
        var $mobApplyButton = $("ul#mobFilterControls li#mobappfltrs span.mobApplyButton");
        if (parseInt(productsCount) > 0) {
            if ($mobApplyButton.length && $mobApplyButton.data("showproductcount") == true) {
                var seeProductsText = $mobApplyButton.data("seeproductstext");
                $mobApplyButton.text(seeProductsText.replace("{0}", productsCount));
            }
        } else {
            $mobApplyButton.text($mobApplyButton.data("applytext"));
        }
    }

    function toggleDesktopFilters() {
        const filterToggleButton = document.querySelector(".productFiltersToggleInner");
        const filterToggleButtonText = filterToggleButton?.querySelector(".toggleFiltersText");
        const plpBody = document.getElementById("BodyWrap");
        let filtersAreVisible = true;

        if (!filterToggleButton) {
            return;
        }

        handleDesktopFilterVisibilityToggle(filtersAreVisible, plpBody, filterToggleButtonText);

        bindToggleFiltersEvents(filterToggleButton, filtersAreVisible, plpBody, filterToggleButtonText);
    }

    function handleDesktopFilterVisibilityToggle(filtersAreVisible, plpBody, filterToggleButtonText) {
        if (!filtersAreVisible) {
            plpBody.classList.add("filtersHidden");
            filterToggleButtonText.textContent = filterToggleButtonText.dataset.closed;
        } else {
            plpBody.classList.remove("filtersHidden");
            filterToggleButtonText.textContent = filterToggleButtonText.dataset.open;
        }
    }

    function bindToggleFiltersEvents(filterToggleButton, filtersAreVisible, plpBody, filterToggleButtonText) {
        filterToggleButton.addEventListener("click", function () {
            filtersAreVisible = !filtersAreVisible;
            handleDesktopFilterVisibilityToggle(filtersAreVisible, plpBody, filterToggleButtonText);
        });
    }

    function bindPaginationEvents() {
        $(".PageNumber a").click(function (e) {
            // Prevent the default click behavior.
            e.preventDefault();
            var filterValues = getSelectedFilters();
            var dataHref = $(this).attr("data-href");

            if (filterValues != null && filterValues.length > 0) {
                dataHref += "&Filter=" + encodeURIComponent(filterValues);
            }

            // get href attr, remove leading #
            var href = dataHref.replace(/^#/, "");
            pushState(filters, "", href);
            return false;
        });
    }

    function setProductsPerPageValue(dpppValue) {
        var ppplId = $("a.pppl");
        var inlineView = getViewNumberType() === "inline";
        if (ppplId.length > 0 && !inlineView) {
            var newViewByVal = dpppValue != null ? getNewViewByValue(dpppValue) : getNewViewByValue();
            var newViewByText = ppplId.data("text") + " " + +newViewByVal;

            if ($(".pppt > span").html() < getBrowseHighPageLength()) {
                var viewByTextHighPageLength = ppplId.data("text") + " " + getBrowseHighPageLength();
                if (ppplId.html() == viewByTextHighPageLength || newViewByText == viewByTextHighPageLength) {
                    newViewByText = ppplId.data("alltext");
                }
            }
            ppplId.html(newViewByText);
            ppplId.attr("data-dppp", newViewByVal);

            var filterValues = getSelectedFilters();
            var dataHref = ppplId.attr("data-href");

            if (filterValues != null && filterValues.length > 0) {
                dataHref += "&Filter=" + filterValues;
            }
            if (dpppValue == null) {
                var aPagehref = dataHref.replace(/^#/, "").replace(/\%\s/, " ");
                pushState(filters, "", aPagehref); // set hash, triggers hashchange on window
            }
            var hrefVal = ppplId.attr("href");
            ppplId.attr("data-href", "#dcp=1&dppp=" + newViewByVal + "&OrderBy=" + getSortOptionSelectedValue());
            ppplId.attr("href", hrefVal.replace("dppp=" + dpppVal, "dppp=" + newViewByVal));
        } else if (ppplId.length > 0 && inlineView) {
            var ppplEl = $("a.pppl.selected");
            var filterValues = getSelectedFilters();

            var dataHref = $("*[data-dppp=" + dpppValue + "]").data("href");

            if (filterValues != null && filterValues.length > 0) {
                dataHref += "&Filter=" + filterValues;
            }

            // if number of products has changed.
            var hash = window.location.hash;
            var regEx = new RegExp("dppp=" + dpppValue + "(&|$)", "i");
            if (!regEx.test(hash)) {
                // Get the selected sort order and
                // merge back into the object
                var aPagehref = dataHref.replace(/^#/, "").replace(/\%\s/, " ");
                var option = hashService.deserialiseParams(aPagehref, true);
                option.OrderBy = getSortOptionSelectedValue();
                pushState(filters, "", $.param(option)); // set hash, triggers hashchange on window
            }

            ppplEl.attr("data-href", "#dcp=1&dppp=" + ppplEl.data("dppp") + "&OrderBy=" + getSortOptionSelectedValue());
        }
    }

    function highlightProductsPerPageValue(val) {
        $(".pppl").each(function () {
            $(this).removeClass("selected");
        });
        if (val) {
            var $elem = $('*[data-dppp="' + val + '"]');
            $elem.addClass("selected");
        } else {
            var $elem = $("a.pppl:first");
            $elem.addClass("selected");
        }
    }

    function setFilterValues(filterList) {
        const selectedFilters = [];
        const removeEmptyFilters = document.getElementById("hdnRemoveEmptyFilters").value === "True";
        const disableFiltersWithZeroCount = document.getElementById("hdnDisableFiltersWithZeroCount").value === "True";

        if (filterList != null && filterList.length > 0) {
            for (let fl = 0; fl < filterList.length; fl++) {
                const filterItems = filterList[fl].filters;
                const filterDict = {};

                for (let fi = 0; fi < filterItems.length; fi++) {
                    filterDict[filterItems[fi].key] = filterItems[fi];
                }

                if (filterItems != null && filterItems.length > 0) {
                    const filterDivs = document.querySelectorAll(`div[class*="${filterList[fl].key}"]`);

                    for (let fd = 0; fd < filterDivs.length; fd++) {
                        const filterDiv = filterDivs[fd];
                        const tagId = filterDiv.querySelector(".FilterAnchor");
                        const filterNameSpanId = filterDiv.querySelector(".FilterName");
                        const filterValueSpanId = filterDiv.querySelector(".FilterValue");

                        if (filterNameSpanId) {
                            const filterName = filterNameSpanId.dataset.filtername;

                            if (filterDict[filterName]) {
                                const filterItem = filterDict[filterName];

                                if (removeEmptyFilters) {
                                    if (filterItem.count == 0) {
                                        filterDiv.classList.add("hidden");
                                    } else {
                                        filterDiv.classList.remove("hidden");
                                    }
                                }
                                //if we have fully working faceted filters enabled we need to disable click action on the
                                if (disableFiltersWithZeroCount) {
                                    if (filterItem.count == 0) {
                                        tagId.classList.add("is_disabled");
                                    } else {
                                        tagId.classList.remove("is_disabled");
                                    }
                                }

                                if (filterValueSpanId) {
                                    if (filterItem.active) {
                                        selectedFilters.push({
                                            Name: filterNameSpanId.dataset.parsedfiltername,
                                            Key: filterItem.key,
                                            Group: filterItem.group,
                                        });
                                    }

                                    filterDiv.setAttribute("data-productcount", filterItem.count);

                                    if (filteredProductsCountWithoutBrackets) {
                                        filterValueSpanId.textContent = filterItem.count ? filterItem.count : "";
                                    } else {
                                        filterValueSpanId.textContent = filterItem.count
                                            ? "(" + filterItem.count + ")"
                                            : "";
                                    }
                                    if (tagId.length > 0 && filterItem.count) {
                                        tagId.classList.remove("is_disabled");
                                    }
                                }
                            } else if (removeEmptyFilters) {
                                filterDiv.classList.add("hidden");
                            } else if (disableFiltersWithZeroCount) {
                                tagId.classList.add("is_disabled");
                            }
                        }
                    }
                }
            }
        }

        setSelectedFilters(selectedFilters);
        window.filters = selectedFilters;
    }

    function sortSelectedFilters(filters) {
        // Sort the filters so that priority
        // values are first to build
        return filters.sort(function (a, b) {
            var orderA = $(a).attr("data-url-order");
            var orderB = $(b).attr("data-url-order");

            // Bring fixed filters to the front
            if ($(a).attr("data-filter-type") === "fixed") orderA = 0;
            if ($(b).attr("data-filter-type") === "fixed") orderB = 0;

            var priorityA = $(a).attr("data-priority");
            var priorityB = $(b).attr("data-priority");
            if (orderA === orderB) {
                return priorityA || 0 < priorityB || 0;
            } else {
                return orderA > orderB ? 0 : -1;
            }
        });
    }

    function buildFiltersObject() {
        var $selectedFiltersQuery = $("#filterlist div.FilterListItem span.SelectedFilter");

        filters = {};

        // Sort the filters so that priority
        // values are first to build
        var $sortedFilters = $selectedFiltersQuery.sort(function (a, b) {
            var orderA = $(a).attr("data-url-order");
            var orderB = $(b).attr("data-url-order");

            // Bring fixed filters to the front
            if ($(a).attr("data-filter-type") === "fixed") orderA = 0;
            if ($(b).attr("data-filter-type") === "fixed") orderB = 0;

            var priorityA = $(a).attr("data-priority");
            var priorityB = $(b).attr("data-priority");
            if (orderA === orderB) {
                return priorityA || 0 < priorityB || 0;
            } else {
                return orderA > orderB ? 0 : -1;
            }
        });

        var sortIndex = 0;

        $sortedFilters.each(function () {
            var item = $(this).attr("data-item");
            var filterType = $(this).attr("data-filter-type");
            var splitItem = item.split("^");
            // Init the filters array.
            // 'sortOrder' key added below to 'filters' object as JS objects does not honour insertion order and stores items
            // by sorting numerics first in ascending, then by string in chronological order.
            if (!filters[splitItem[0]]) {
                filters[splitItem[0]] = {
                    type: filterType,
                    keys: [],
                    sortOrder: sortIndex++,
                };
            }
            // Push the filter item to the correct prop's array
            if (splitItem.length > 1) {
                filters[splitItem[0]].keys.push(splitItem[1]);
            }
        });

        var priceSliderValue = $("#hdnPriceSliderValue").val();
        if (priceSliderValue != null && priceSliderValue.length > 0) {
            if (!filters["APRI"]) {
                filters["APRI"] = {
                    type: "",
                    keys: [],
                    sortOrder: sortIndex,
                };
            }
            var priceFilter = priceSliderValue.split("^");
            if (priceFilter.length > 1) {
                // Push the filter item to the correct prop's array
                filters["APRI"].keys.push(priceFilter[1]);
            }
        }
    }

    function buildFilter(filterSelection, i, key, property) {
        if (i === 0) {
            // First part of the filter item e.g. ABRA^
            if (filterSelection === "") {
                filterSelection += property + "^";
            } else {
                filterSelection += "|" + property + "^";
            }
            filterSelection += key;
        } else {
            filterSelection += "," + key;
        }
        return filterSelection;
    }

    function getSortedSelectedFilters() {
        return $.map(filters, function (v, i) {
            return { key: i, property: v };
        }).sort(function (a, b) {
            return a.property.sortOrder - b.property.sortOrder;
        });
    }

    function getSelectedFilters(includeFixed) {
        if (isEmpty(filters) || isLegacy) {
            return getSelectedFiltersLegacy();
        }
        var filterSelection = "";
        // var brandsDone = false;
        var variableFiltersComplete = false;

        var sortedFilters = getSortedSelectedFilters();

        for (var index in sortedFilters) {
            if (
                (sortedFilters[index].property.type !== "fixed" && sortedFilters[index].property.type !== "variable") ||
                variableFiltersComplete ||
                includeFixed
            ) {
                $.each(sortedFilters[index].property.keys, function (i, k) {
                    filterSelection = buildFilter(filterSelection, i, k, sortedFilters[index].key);
                });
            } else {
                var slicedFilters = sortedFilters[index].property.keys.slice(
                    1,
                    sortedFilters[index].property.keys.length,
                );
                $.each(slicedFilters, function (i, k) {
                    filterSelection = buildFilter(filterSelection, i, k, sortedFilters[index].key);
                });
            }
            // Mark variable filters done
            if (sortedFilters[index].property.type === "variable") variableFiltersComplete = true;
            // After any brand, make further filters appear in the hash
            // if (property === 'ABRA') {
            // brandsDone = true;
            // }
        }

        return filterSelection;
    }

    // Assuming you have a click event handler for items
    function handleItemPosition(event) {
        const clickedItem = event.currentTarget;
        const positionValue = clickedItem.getAttribute("data-insights-position");
        if (positionValue) {
            localStorage.setItem("itemPosition", positionValue);
        }
    }

    // Attach the click event handler to the items
    const items = document.querySelectorAll("[data-insights-position]");
    items.forEach(function (item) {
        item.addEventListener("click", handleItemPosition);
    });

    // When the page loads (e.g., during the "DOMContentLoaded" event), you can restore the scroll position based on user interaction.
    window.addEventListener("DOMContentLoaded", function () {
        let storedItemPosition = localStorage.getItem("itemPosition");
        if (storedItemPosition) {
            let itemWithPosition = document.querySelector('[data-insights-position="' + storedItemPosition + '"]');
            if (itemWithPosition) {
                itemWithPosition.scrollTop = storedItemPosition;
            }
        }
    });

    function getSelectedFiltersLegacy() {
        var currentFilterName = "";
        var newFilterString = "";
        var priceFilterDone = false;

        var $selectedFiltersQuery = $("#filterlist div.FilterListItem span.SelectedFilter");

        $selectedFiltersQuery.each(function () {
            var item = $(this).attr("data-item");
            var splitItem = item.split("^");
            var newFilterName = splitItem[0].toLowerCase();

            if (newFilterString == "") {
                currentFilterName = newFilterName;
                newFilterString = item;

                if (currentFilterName == "apri") priceFilterDone = true;
            } else {
                if (currentFilterName != newFilterName) {
                    currentFilterName = newFilterName;
                    newFilterString += "|" + item;
                } else {
                    newFilterString += "," + splitItem[1];
                }
            }
        });

        if (!priceFilterDone) {
            var priceSliderValue = $("#hdnPriceSliderValue").val();
            if (priceSliderValue != null && priceSliderValue.length > 0) {
                if (newFilterString != null && newFilterString.length > 0) {
                    newFilterString += "|" + priceSliderValue;
                } else {
                    newFilterString = priceSliderValue;
                }
            }
        }
        return newFilterString;
    }

    function getNewViewByValue(val) {
        var dpppCurrentValue = val != null ? val : $("a.pppl").attr("data-dppp");
        var newViewByVal =
            dpppCurrentValue == getBrowseHighPageLength() ? getBrowseHighPageLength() : getBrowseLowPageLength();
        return newViewByVal;
    }

    var originalPath = location.pathname;

    function getStateKey() {
        var newViewByVal = $("a.pppl.selected").attr("data-dppp");
        if (!newViewByVal) {
            newViewByVal = getNewViewByValue();
        }

        var defaultStateKey = "#dcp=1&dppp=" + newViewByVal + "&OrderBy=" + getSortOptionSelectedValue();
        var filterStateKey = "";

        var selectedFiltersValue = getSelectedFilters(isLegacy);

        if (selectedFiltersValue != null && selectedFiltersValue.length > 0) {
            filterStateKey = defaultStateKey + "&Filter=" + encodeURIComponent(selectedFiltersValue);
        } else {
            filterStateKey = defaultStateKey + "&Filter=none";
        }

        return filterStateKey;
    }

    function constructAndSetFilterStateKey() {
        // get href attr, remove leading #
        var filterStateKey = getStateKey();
        var valueFilter = filterStateKey.replace(/^#/, "");

        if (isLegacy) {
            pushState(filters, "", valueFilter); // option

            return;
        }

        // Progressively build the path in filter order
        // The latter is currently based on UI order.
        var path = "";
        var fixedFiltersComplete = false;
        var variableFiltersComplete = false;

        var sortedFilters = getSortedSelectedFilters();

        for (var index in sortedFilters) {
            if (sortedFilters[index].property.type === "fixed" && !fixedFiltersComplete) {
                var firstFilter = sortedFilters[index].property.keys[0];
                path += "/" + formatPath(firstFilter.toLowerCase().trim(), true);
                fixedFiltersComplete = true;
                // Any number of variable, but only one from each filter
            } else if (sortedFilters[index].property.type === "variable" && !variableFiltersComplete) {
                var firstFilter = sortedFilters[index].property.keys[0];
                path += "/" + formatPath(firstFilter.toLowerCase().trim(), true);
                variableFiltersComplete = true;
            }
        }

        // path = formatPath(path, false);
        originalPath = removeTrailingSlash(getBaseCategoryUrl());

        // Break back to the original path if it's not changed.
        if (path !== originalPath) {
            pushState(filters, originalPath + path + "#" + valueFilter); // option
        } else {
            pushState(filters, originalPath); // option
        }
    }

    function calcFiltersFromPath(path, fixed, variable, set) {
        // Strip out all fixed/variable filters
        // from the original path
        var pathArray = path.split("/");
        $.each(pathArray, function (i, item) {
            var matched = false;
            // Match any fixed/variable filter value
            $(
                "#filterlist div.FilterListItem span.SelectedFilter, #filterlist div.FilterListItem span.SelectableFilter",
            ).each(function () {
                var filterType = $(this).attr("data-filter-type");
                if ((fixed && filterType === "fixed") || (variable && filterType === "variable")) {
                    var $filterName = $(this).find("span.FilterName");
                    if (
                        formatPath($filterName.attr("data-filtername"), true).toLowerCase().trim() ===
                        item.toLowerCase()
                    ) {
                        matched = true;
                        if (set) {
                            $(this).addClass("SelectedFilter");
                        }
                        // return false;
                    }
                }
            });
            if (!matched) {
                path += "/" + item;
            }
        });

        return path;
    }

    function formatPath(path, stripSlashes) {
        // Format path
        // Replace spaces with hyphens and remove any trailing slash
        path = path?.replace(/&/g, "and");
        path = path?.replace(/%/g, "percent");
        path = path?.replace(/ /g, "-");
        path = path?.replace(/\+/g, "%2B");
        path = path?.replace(/\?/g, "%3F");
        path = path?.replace(/\!/g, "%21");
        path = path?.replace(/°/g, "deg");
        path = path?.replace(/#/g, "hash-");
        path = path?.replace(/[()\[\]<>.,'":\*]/g, "");
        if (stripSlashes) {
            path = path?.replace(/\//g, "-");
            path = path?.replace(/\\/g, "-");
        }
        return path;
    }

    function removeTrailingSlash(path) {
        if (path.substr(path.length - 1) == "/") {
            return path.substr(0, path.length - 1);
        }
        return path;
    }

    function getProductListContainerAttributeValue(attributeName) {
        var attributeValue = "";
        if (attributeName == null || attributeName.length == 0) return attributeValue;

        var $productListContainer = $("#productlistcontainer");
        attributeValue = $productListContainer.data(attributeName);
        if (attributeValue == null) {
            attributeValue = "";
        }
        return attributeValue.toString();
    }

    function getURLparameter(name) {
        var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(window.location.search);

        return results !== null ? results[1] : null;
    }

    var currentGetProductsXhr = null;

    function getProductsInformation(clearFilters) {
        showLoadingView();
        var filterSelection = "";
        $("#initialActiveColumns").val($("#activecolumns").val());
        if (!clearFilters) {
            filterSelection = getSelectedFilters(true);
        }

        var sortOptionVal = getSortOptionSelectedValue();
        var descFilterText = getProductListContainerAttributeValue("descfilter");
        var categoryNameValue = getProductListContainerAttributeValue("category");
        var searchTermCategoryValue = getProductListContainerAttributeValue("searchtermcategory");
        var selectedCurrencyValue = getProductListContainerAttributeValue("fltrselectedcurrency");

        if (currentGetProductsXhr != null) currentGetProductsXhr.abort();

        var isSearch = descFilterText.length > 0;

        var productApiRequestUrl = "/product";
        productApiRequestUrl += isSearch ? "/search" : "/getforcategory";

        currentGetProductsXhr = $.ajax({
            cache: true,
            type: "GET",
            url: productApiRequestUrl,
            data: {
                categoryId: categoryNameValue,
                page: dcpVal,
                productsPerPage: dpppVal,
                sortOption: sortOptionVal,
                selectedFilters: filterSelection,
                isSearch: descFilterText.length > 0,
                searchText: descFilterText,
                columns: $("#activecolumns").val(),
                mobileColumns: $("#activemobilecolumns").val(),
                clearFilters: clearFilters,
                pathName: decodeURIComponent(window.location.pathname),
                searchTermCategory: searchTermCategoryValue,
                selectedCurrency: selectedCurrencyValue,
                portalSiteId: portalSiteId,
                searchCategory: getURLparameter("category"),
            },
            dataType: "json",
            success: function (data) {
                if (data != null) {
                    // Set sashJson property
                    if (!!data.products) {
                        [...data.products].forEach((p) => {
                            if (!!p.sash) {
                                p.sashJson = JSON.stringify(p.sash);
                            }
                        });
                    }
                    data.ClearAllFiltersVisible = !!filterSelection;
                    bindProductData(data);
                } else {
                    showError("Product data endpoint returned null");
                }
                window.anonymousWishlist.highlightIconIfProductsOnWishlistPLP();
                showComplete(data);
                if (typeof window.CustomEvent === "function")
                    window.dispatchEvent(new CustomEvent("productListUpdated"));
            },
            error: function (xhr, textStatus, errorThrown) {
                if (textStatus != "abort") showError(textStatus + errorThrown);
            },
            complete: function (data) {
                currentGetProductsXhr = null;
            },
        });
    }

    function disableMobileFilterButton(value) {
        const mobFilters = document.getElementById("mobappfltrs");
        const mobFilterButton = document.getElementById("mobFilterButton");
        const mobFilterDisabledText = mobFilterButton.getAttribute("data-mobfilterdisabledtext");
        const mobFilterEnabledText = mobFilterButton.getAttribute("data-mobfilterenabledtext");
        if (value === "disabled") {
            mobFilters.classList.add("mobFilterDisabled");
            mobFilterButton.textContent = mobFilterDisabledText;
        } else if (value === "enabled") {
            mobFilters.classList.remove("mobFilterDisabled");
            mobFilterButton.textContent = mobFilterEnabledText;
        }
    }

    function bindProductData(d) {
        if (EnableScrollToTop(d)) {
            // Scroll to top before updating HTML to leverage lazy loading
            scrollToTop();
        }
        var prdTemplate = $("#products-template").html();
        var hasProducts = d != null && d.products.length > 0;
        var hasFilters = d != null && d.filters.length > 0;
        const isFilterContainer = !!document.getElementById("FilterContainer");
        var clearAllFiltersVisible = d != null && d.ClearAllFiltersVisible;
        var noProductsText = $("#productlistcontainer").data("noproductstext");
        var template = Handlebars.compile(prdTemplate);
        var html = hasProducts ? template(d) : "<li>" + noProductsText + "</li>";
        var paginationNextIcon = $(".swipeNextClick .global-icon-chevron");
        var paginationPrevIcon = $(".swipePrevClick .global-icon-chevron");
        setMemberPricingProductOnPlpBodyClass(d);
        $("#navlist").html(html);
        removeLoadingView();
        if (hasProducts) {
            $(".pagination").show();
            if (!productCountProductsPerPageEnabled()) {
                setTotalProductCount(d.numberOfProducts);
            } else {
                setProductCount(d.products.length, d.numberOfProducts);
            }
            $(".PageNumber").html(getPaginationHtml(d));

            if (!paginationNextIcon && !paginationPrevIcon) {
                $(".PageNumber .PrevLink").html($(".PageNumber").data("previoustext"));
                $(".PageNumber .NextLink").html($(".PageNumber").data("nexttext"));
            }

            var browseV3LowPageLength = getBrowseLowPageLength();
            var browseV3HighPageLength = getBrowseHighPageLength();
            var ppplId = $("a.pppl");
            var inlineView = getViewNumberType() === "inline";
            if (ppplId.length > 0 && !inlineView) {
                if (
                    browseV3LowPageLength != browseV3HighPageLength &&
                    $(".pppt > span").html() > browseV3LowPageLength
                ) {
                    var newViewByText;
                    if ($(".pppt > span").html() < browseV3HighPageLength) {
                        var viewByTextHighPageLength = ppplId.data("text") + " " + browseV3HighPageLength;
                        if (ppplId != null && ppplId.html() == viewByTextHighPageLength) {
                            newViewByText = ppplId.data("alltext");
                            ppplId.html(newViewByText);
                        }
                    } else {
                        var dppValue = ppplId.attr("data-dppp");
                        if (ppplId != null && ppplId.html() == ppplId.data("alltext")) {
                            newViewByText = ppplId.data("text") + " " + dppValue;
                            ppplId.html(newViewByText);
                        }
                    }
                    $("div.ViewPerPage").css("display", ""); //show
                } else {
                    $("div.ViewPerPage").hide();
                }
            }
            applyMobileFiltersCount();
            bindPaginationEvents();
            InitializeAlternateImageEvents();
            InitializeQuickBuyAndWishListEvents();
            InitializeGTMEvents();
            postGTMProductListing(d);
        } else {
            $(".pagination").hide();
        }

        if (hasFilters && isFilterContainer) {
            $("#lblCategorySearchCopy").hide();
            setFilterValues(d.filters);
        } else {
            $(".FilterValue").html("");
        }
        const mobFilterButton = document.getElementById("mobFilterButton");
        if (clearAllFiltersVisible) {
            $("#clrallfltrs, #mobclrfltrs").css("display", ""); //Show
            $("#mobappfltrs").addClass("clearFiltersOpen");
            if (mobFilterButton?.hasAttribute("data-mobfilterdisabledtext")) {
                disableMobileFilterButton("enabled");
            }
            if ($("#CategoryAcross").text() != "") {
                $("#CategoryAcross").hide();
                $("#divsiblinglistwrapper").hide();
                $("#divViewMore").hide();
            }

            displayMobileFiltersCount(d.numberOfProducts);
        } else {
            $("#clrallfltrs, #mobclrfltrs").hide();
            $("#mobappfltrs").removeClass("clearFiltersOpen");
            if (mobFilterButton?.hasAttribute("data-mobfilterenabledtext")) {
                disableMobileFilterButton("disabled");
            }
            if ($("#CategoryAcross").text() != "") {
                $("#CategoryAcross").show();
                $("#divsiblinglistwrapper").show();
                $("#divViewMore").show();
            }

            displayMobileFiltersCount(0);
        }

        // Recently viewed
        if (recentlyViewedProductsHighlightEnabled) {
            highlightRecentlyViewedProducts();
        }

        // Re-gen URLs
        generateFilterUrls();
        // Populate filter copy
        if (!isLegacy) {
            if (!isBot()) {
                populateBreadcrumb();
            }
            populateSeoInfo(d.meta);
        }

        hasUserInteractedWithFilters = false;

        if (d.searchQueryId && d.searchIndex) {
            algoliaUtil.setRootSearchDataAttributes(d.searchQueryId, d.searchIndex);
        }
    }

    function setMemberPricingProductOnPlpBodyClass(productListResponse) {
        if (!productListResponse?.memberPricingProductOnPlpBodyClassName) return;
        document.body.classList.toggle(
            productListResponse.memberPricingProductOnPlpBodyClassName,
            productListResponse?.hasMemberPricingProductOnPlp,
        );
    }

    function populateBreadcrumb() {
        var $bc = $("#BreadcrumbGroup").find("ol");
        var $selectedFiltersQuery = $("#filterlist div.FilterListItem span.SelectedFilter");
        var $sortedFilters = sortSelectedFilters($selectedFiltersQuery);
        var fixedFiltersDone = false;
        var variableFiltersDone = false;

        // Work out what types of filter are in the last part(s) of the URL
        var filterTypes = getFilterTypesFromPath();

        var basePath = getBaseCategoryUrl();

        // Clean the breadcrumb for what was inserted
        $bc.find(".bc-inserted").remove();
        var $last = $bc.find("li.last");
        // Add the path to the last item
        if (filterTypes["fixed"] || filterTypes["variable"]) {
            var lastName = $last.text();
            $last.html('<a href="' + basePath + '">' + lastName + "</a>");
            $last.removeClass("last");
        }

        $sortedFilters.each(function (i, el) {
            var $el = $(this);
            var filterType = $el.attr("data-filter-type");
            // Skip the filters if done
            if (filterType === "deindexed") return;
            if (filterType === "fixed" && fixedFiltersDone) return;
            if (filterType === "variable" && variableFiltersDone) return;
            var name = $el.children("span.FilterName").attr("data-filtername");
            // Add the new item
            $bc.append('<span class="breadcrumb-seperator bc-inserted"> / </span>');
            if (filterTypes["fixed"] && !filterTypes["variable"]) {
                $bc.append('<li class="bc-inserted">' + name + "</li>");
            } else if (!filterTypes["fixed"] && filterTypes["variable"]) {
                $bc.append('<li class="bc-inserted">' + name + "</li>");
            } else if (filterTypes["fixed"] && filterTypes["variable"] && filterType === "variable") {
                $bc.append('<li class="bc-inserted">' + name + "</li>");
            } else {
                var aTag =
                    '<a href="' + basePath + "/" + formatPath(name.toLowerCase().trim(), true) + '">' + name + "</a>";
                $bc.append('<li class="bc-inserted">' + aTag + "</li>");
            }
            // Set the fixed/variable filter status
            if (filterType === "fixed") {
                fixedFiltersDone = true;
            }
            if (filterType === "variable") {
                variableFiltersDone = true;
            }
        });
        $bc.find("li:last").addClass("last");
    }

    function populateSeoInfo(meta) {
        const indexedFilters =
            document.querySelector("[data-filter-type='fixed']") ||
            document.querySelector("[data-filter-type='variable']");
        const applyMetaOnlyWhenIndexedFiltersExist = $("#hdnApplyMetaOnlyWhenIndexedFiltersExist").val() == "true";
        if (applyMetaOnlyWhenIndexedFiltersExist && !indexedFilters) return;

        if (meta) {
            // Title
            if (meta.title) {
                document.title = meta.title;
                // Meta
                $('meta[property="og:title"]').remove();
                $("head").append('<meta property="og:title" content="' + meta.title + '">');
            }
            if (meta.description) {
                $('meta[name="DESCRIPTION"]').remove();
                $('meta[name="description"]').remove();
                $("head").append('<meta name="description" content="' + meta.description + '">');
            }
            // H1
            $("h1#catHeader").find("span").text(meta.h1);
            // Copy
            $("#lblCategoryCopy").html(meta.copy);
            // Canonical Url
            if (meta.canonicalUrl) {
                var fullUrl = GetFullCanonicalUrl(meta.canonicalUrl);
                $('link[rel="canonical"]').attr("href", fullUrl);
            }
            setupCategoryCopyReadMoreLess();
            plpCategoryHeader.initCategoryHeaderSwiper();
        }
    }

    function GetFullCanonicalUrl(canonicalUrl) {
        return `https://${window.location.host}${canonicalUrl}`;
    }

    function InitializeQuickBuyAndWishListEvents() {
        if (typeof window.initializeHotspotsQuickBuyAndWishListEvents == "function") {
            window.initializeHotspotsQuickBuyAndWishListEvents("div.productimage.s-productthumbimage");
        }
    }

    function EnableScrollToTop(d) {
        // Check if portal setting enabled
        if (!productListingScrollTopOnFilterChange && hasUserInteractedWithFilters) {
            return false;
        }
        if (hasUserInteractedWithFilters && d != null) {
            var activeColumns = $("#activecolumns").val();
            var totalScrollableProducts = activeColumns * noOfRowsToScroll;
            return d.products.length <= totalScrollableProducts;
        }
        return true;
    }

    function getBrowseLowPageLength() {
        return checkVariableExists(window.browseLowPageLength) ? window.browseLowPageLength : 24;
    }

    function getBrowseHighPageLength() {
        return checkVariableExists(window.browseHighPageLength) ? window.browseHighPageLength : 100;
    }

    function getNoOfItemsInGTMPost() {
        return getProductListContainerAttributeValue("noofitemsingtmpost");
    }

    function postGTMProductListing(d) {
        try {
            if (window.parent.dataLayer != null) {
                var columns = $("#activecolumns").val();
                var noOfItems = parseInt(getNoOfItemsInGTMPost());
                if (isNaN(noOfItems)) noOfItems = 25;
                var prodCount = d.products.length >= noOfItems ? noOfItems : d.products.length;
                var prods = [];
                for (var i = 0; i < prodCount; i++) {
                    prods[prods.length] = {
                        variantId: d.products[i].ItemCode.toString(),
                        position: (i + 1).toString(),
                    };
                }

                if (window.parent.dataLayer != null)
                    window.parent.dataLayer.push({
                        event: "productListing",
                        itemsPerRow: columns.toString(),
                        listingProducts: prods,
                    });
            }
        } catch (e) {}
    }

    function showError(d) {
        logClientScriptException(d);
        window.location = window.location.origin + "/500"; // redirect user to 500 page
    }
    function showComplete(d) {
        removeLoadingView();
    }

    function showLoadingView() {
        $("#ProductContainer").fadeTo(0, 0.5);
    }

    function removeLoadingView() {
        $("#ProductContainer").fadeTo(0, 1);
    }

    function inSingleBrowseBarMode() {
        return $(".singleBrowseBar").length != 0;
    }

    function getPaginationHtml(searchResult) {
        var paginationTemplate = $("#pagination-template").html();

        var pages = getPaginationItems(
            searchResult.numberOfPages,
            searchResult.currentPage,
            $(".PageNumber").data("maxsearchdisplaypages"),
        );

        var descFilterText = getProductListContainerAttributeValue("descfilter");
        var categoryNameValue = getProductListContainerAttributeValue("category");

        var pagination = {
            RawUrl: $(".PageNumber").data("rawurl"),
            NumberOfPages: searchResult.numberOfPages,
            CurrentPage: searchResult.currentPage,
            ProductsPerPage: dpppVal,
            SortOption: getSortOptionSelectedValue(),
            IsSearch: !!descFilterText,
            NextText: $(".PageNumber").data("nexttext"),
            PreviousText: $(".PageNumber").data("previoustext"),
            Category: categoryNameValue,
            Pages: pages,
            IsCurrentPageFirst: searchResult.currentPage == 1,
            IsCurrentPageLast: searchResult.currentPage == searchResult.numberOfPages,
        };

        var template = Handlebars.compile(paginationTemplate);

        return template(pagination);
    }

    function getPaginationItems(numberOfPages, currentPage, maxSearchDisplayPages) {
        var paginationItems = [];

        var alreadyShownPreviousEllipsis = false;
        var alreadyShownNextEllipsis = false;

        if (numberOfPages > 1) {
            var searchDisplayPages = currentPage == 1 || currentPage == numberOfPages ? 3 : maxSearchDisplayPages;

            if (currentPage >= 1) {
                paginationItems.push({
                    PreviousButton: true,
                    PageNumber: currentPage - 1,
                });
            }

            for (var i = 1; i <= numberOfPages; i++) {
                //suppress all pages significantly lower than the current page, always show the first page
                if (i > 1 && i < currentPage - searchDisplayPages + 1) {
                    if (!alreadyShownPreviousEllipsis) {
                        paginationItems.push({
                            Ellipsis: true,
                            PageNumber: i - 1,
                        });
                        alreadyShownPreviousEllipsis = true;
                    }
                    continue;
                }

                //suppress all pages significantly higher than the current page, always show the last page
                if (i < numberOfPages && i + 1 > currentPage + searchDisplayPages) {
                    if (!alreadyShownNextEllipsis) {
                        paginationItems.push({
                            Ellipsis: true,
                        });
                        alreadyShownNextEllipsis = true;
                    }
                    continue;
                }

                if (i == currentPage) {
                    paginationItems.push({
                        CurrentPage: true,
                        PageNumber: i,
                    });
                }

                if (i > currentPage) {
                    paginationItems.push({
                        NextPage: true,
                        PageNumber: i,
                    });
                }

                if (i < currentPage) {
                    paginationItems.push({
                        PreviousPage: true,
                        PageNumber: i,
                    });
                }
            }

            if (currentPage <= numberOfPages) {
                paginationItems.push({
                    NextButton: true,
                    PageNumber: currentPage + 1,
                });
            }

            if ($("#divPagination").data("hidequerystringinhref") === "True") {
                paginationItems = paginationItems.map(function (x) {
                    getProductListContainerAttributeValue("defaultpagelength") == dpppVal &&
                    getProductListContainerAttributeValue("defaultsortorder") == getSortOptionSelectedValue()
                        ? (x.ShowQueryStringInHref = false)
                        : (x.ShowQueryStringInHref = true);

                    x.IsRootPage = x.PageNumber == 1;

                    return x;
                });
            } else {
                paginationItems = paginationItems.map(function (x) {
                    x.ShowQueryStringInHref = true;

                    return x;
                });
            }
        }

        return paginationItems;
    }

    function productCountProductsPerPageEnabled() {
        if (productsPerPageInProductCountEnabled !== undefined) return productsPerPageInProductCountEnabled;

        productsPerPageInProductCountEnabled = $("#hdnProductsPerPageInProductCountEnabled").val() === "true";

        return productsPerPageInProductCountEnabled;
    }

    var productsCountSingular;
    var productsCountPlural;

    function setTotalProductCount(totalProducts) {
        if (productsCountSingular === undefined) productsCountSingular = $(".pppt").data("singular");
        if (productsCountPlural === undefined) productsCountPlural = $(".pppt").data("plural");

        var productCountText = "";
        if (productsCountPlural && productsCountSingular) {
            productCountText = totalProducts !== 1 ? productsCountPlural : productsCountSingular;
        }

        var productCount = $(".pppt");
        if (productCount) {
            var totalProductsContainer = $(".totalProducts", productCount);
            var productsCountTextContainer = $(".productsCountText", productCount);

            if (totalProductsContainer && productsCountTextContainer) {
                totalProductsContainer.html(totalProducts);
                productsCountTextContainer.html(productCountText);
            } else {
                $(productCount).html(totalProducts + " " + productCountText);
            }
        }
    }

    function setProductCount(currentProducts, totalProducts) {
        if (productsCountSingular === undefined) productsCountSingular = $(".pppt").data("singular");
        if (productsCountPlural === undefined) productsCountPlural = $(".pppt").data("plural");

        var productCount = $(".pppt");
        $(".currentProducts", productCount).html(currentProducts);
        $(".totalProducts", productCount).html(totalProducts);
        var productCountText = totalProducts !== 1 ? productsCountPlural : productsCountSingular;
        $(".productsCountText", productCount).html(productCountText);
    }

    function ToggleSelectedFilter(name, key) {
        var filterPanel = $("#filterlist");

        var filterAnchor = filterPanel
            .find(".FilterListItem." + name + '[data-productname="' + key + '"]')
            .children(".FilterAnchor");

        window.anonymousWishlist.highlightIconIfProductsOnWishlistPLP();
        SetVal(null, name, key, filterAnchor);
    }

    function setSelectedFilters(filters) {
        var filterContainers = $(".SelectedFiltersContainer");

        if (!filterContainers || !filterContainers.length) return;

        var selectedFilters = [];

        if (filters && filters.length) {
            selectedFilters = filters;
            mobFiltersContainerEle.addClass("filtersActive");
        } else {
            mobFiltersContainerEle.removeClass("filtersActive");
        }

        $(".SelectedFiltersWrapper").toggle(selectedFilters.length > 0);

        var template = $("#selected-filters-template").html();

        if (!template) return;

        var compiled = Handlebars.compile(template);

        var content = compiled({ Filters: selectedFilters });

        filterContainers.html(content);

        // Also do it per group
        var templateGroup = $("#selected-filters-template-group").html();
        if (!templateGroup) return;

        var compiledGroup = Handlebars.compile(templateGroup);
        var limit = $("#filterGroupSettings").attr("data-overflow");

        $(".mobAppliedFilters").each(function (i, item) {
            var group = $(item).attr("data-item");
            if (group) {
                var groupFilters = [];
                for (var i = 0; i < selectedFilters.length; i++) {
                    if (selectedFilters[i].Group.toLowerCase() === group) {
                        groupFilters.push(selectedFilters[i]);
                    }
                }
                var contentGroup = compiledGroup({
                    Filters: groupFilters.slice(0, limit),
                    ShowOverflow: groupFilters.length - limit > 0,
                    Overflow: groupFilters.length - limit,
                });
                $("#SelectedFiltersWrapper-" + group.toUpperCase()).html(contentGroup);
            }
        });
    }
    var overlay = $("#productlistcontainer .productlist-overlay");
    function displayFilters(event) {
        $(".hiddenMenuOpen").addClass("DesktopHide");
        $("#ToggleFiltersContainer").removeClass("DesktopHide");
        $(".toggleFilters").addClass("filtersOpen");
        overlay.addClass("activeOverlay");
        addInnerFilterHeight();
        if (event) event.stopPropagation();
    }

    function hideFilters(event) {
        $(".hiddenMenuOpen").removeClass("DesktopHide");
        $("#ToggleFiltersContainer").addClass("DesktopHide");
        $(".toggleFilters").removeClass("filtersOpen");
        overlay.removeClass("activeOverlay");
        if (event) event.stopPropagation();
    }

    function addInnerFilterHeight() {
        var filterInnerHeight;
        if ($(".fixed-filters-on").length === 0 && $(".hero-wrapper").length === 0) {
            filterInnerHeight =
                $(window).height() -
                ($("#DescriptionWrapper").height() +
                    $(".BreadcrumbGroupWrapper").height() +
                    $(".HeaderTopSpacer").height() +
                    $("#fixed-filters").height());
        } else if ($(".fixed-filters-on").length === 0 && $(".hero-wrapper").length >= 1) {
            filterInnerHeight =
                $(window).height() -
                ($("#DescriptionWrapper").height() - $(window).scrollTop() + $("#fixed-filters").height());
        } else {
            filterInnerHeight =
                $(window).height() -
                (($(".hero-wrapper").length >= 1 ? $("#HeaderGroup").height() : $(".HeaderTopSpacer").height()) +
                    $("#fixed-filters").height());
        }
        $(".toggleFilterInner.toggleFilterInnerHeight").height(filterInnerHeight);
    }
    function isToggleMenuEnabled() {
        if (toggleMenuEnabled !== undefined) return toggleMenuEnabled;

        toggleMenuEnabled = $("#hdnToggleMenuEnabled").val() === "true";

        return toggleMenuEnabled;
    }

    function isStickyMenuEnabled() {
        if (stickyMenuEnabled !== undefined) return stickyMenuEnabled;

        stickyMenuEnabled = $("#hdnStickyMenuEnabled").val() === "true";

        return stickyMenuEnabled;
    }

    function isFullWidthStickyMenuEnabled() {
        if (fullWidthStickyMenuEnabled !== undefined) return fullWidthStickyMenuEnabled;

        fullWidthStickyMenuEnabled = $("#hdnFullWidthStickyMenuEnabled").val() === "true";

        return fullWidthStickyMenuEnabled;
    }

    function isHideToggleMenuOnScrollEnabled() {
        if (hideToggleMenuOnScrollEnabled !== undefined) return hideToggleMenuOnScrollEnabled;

        hideToggleMenuOnScrollEnabled = $("#hdnHideToggleMenuOnScrollEnabled").val() === "true";

        return hideToggleMenuOnScrollEnabled;
    }

    function isToggleDesktopFiltersEnabled() {
        if (toggleDesktopFiltersEnabled !== undefined) return toggleDesktopFiltersEnabled;

        toggleDesktopFiltersEnabled = $("#hdnToggleDesktopFiltersEnabled").val() === "true";

        return toggleDesktopFiltersEnabled;
    }

    function setupToggleMenu() {
        if (!isToggleMenuEnabled()) {
            $(".DesktopHidden").removeClass("DesktopHide");
            return;
        }

        $("#ToggleFiltersContainer").addClass("ToggleEnabled");

        $(".showFilters").click(displayFilters);
        $(".hideFilters").click(hideFilters);
        $(".toggleFilters").click(function (e) {
            var target = $(this);
            if (target.hasClass("filtersOpen")) hideFilters(e);
            else displayFilters(e);
        });

        $("#FilterContainer").scroll(function (e) {
            e.stopPropagation();
        });
        $("#FilterContainer").click(function (e) {
            e.stopPropagation();
        });
        $("#innerfiltercontainer").click(function (e) {
            e.stopPropagation();
        });

        if (isHideToggleMenuOnScrollEnabled()) {
            $(window).scroll(function (e) {
                // If scroll scrolls Refine off top
                var elementTop = $(".FiltersTitle").offset().top;
                var viewportTop = $(window).scrollTop() + 90;

                if (viewportTop > elementTop) hideFilters();
            });
            $(window).click(function (e) {
                hideFilters();
            });
        } else {
            $("#fixed-filters").mouseleave(function () {
                hideFilters();
            });
        }
    }

    function isSortOptionsTemplateEnabled() {
        if (sortOptionsTemplateEnabled !== undefined) return sortOptionsTemplateEnabled;

        sortOptionsTemplateEnabled = $("#hdnSortOptionsTemplateEnabled").val() === "true";

        return sortOptionsTemplateEnabled;
    }

    function getSortOptionSelectedValue() {
        let selectedSortOption = null;

        if (isSortOptionsTemplateEnabled()) {
            selectedSortOption = $(".ddlSortOptions .ddlSortOption[data-selected='true']").data("optionvalue");
        } else {
            selectedSortOption = $("[id$=ddlSortOptions] option:selected").val();
        }

        if (selectedSortOption == null) {
            selectedSortOption = $(".sort-by-in-filters input:checked").attr("value");
        }

        return selectedSortOption;
    }

    function toggleSortOptions() {
        var options = $(".sortOptionsContainer");
        var overlay = $("#productlistcontainer .productlist-overlay");
        if (options.hasClass("ddlSortOptionsOpen")) {
            options.removeClass("ddlSortOptionsOpen");
            overlay.removeClass("activeOverlay");
        } else {
            hideFilters();
            options.addClass("ddlSortOptionsOpen");
            overlay.addClass("activeOverlay");
        }
    }

    function updateStateOnSortOptionChange(currentSelectedOrderBy) {
        dcpVal = 1;
        var newViewByVal = $("a.pppl.selected").attr("data-dppp");
        if (!newViewByVal) {
            newViewByVal = getNewViewByValue();
        }
        var defaultStateKey = "#dcp=1&dppp=" + newViewByVal + "&OrderBy=" + currentSelectedOrderBy;
        var aPagehref = defaultStateKey.replace(/^#/, "");

        // Add the filters
        var filterValues = getSelectedFilters();
        if (filterValues != null && filterValues.length > 0) {
            aPagehref += "&Filter=" + filterValues;
        }

        pushState(filters, "", aPagehref);
    }

    function setupSortOptionDropdown() {
        if (!isSortOptionsTemplateEnabled()) return;

        $(".sortOptionsHeader").click(toggleSortOptions);

        $(".ddlSortOption").click(function () {
            var target = $(this);
            var currentSelectedOrderBy = target.data("optionvalue");
            setOrderByDdlValue(currentSelectedOrderBy);
            toggleSortOptions();
            updateStateOnSortOptionChange(currentSelectedOrderBy);
        });

        $(".sortOptionsContainer").on("mouseleave", function () {
            $(this).removeClass("ddlSortOptionsOpen");
            overlay.removeClass("activeOverlay");
        });
    }

    function getViewNumberType() {
        viewNumberType = $("#hdnProductListingViewNumberType").val();

        if (!viewNumberType) {
            return "Default";
        }
        return viewNumberType;
    }

    function setupCategoryCopyReadMoreLess() {
        categoryTextWrapper = document.querySelector(".mobile-read-more-less-enabled .textDesc");
        categoryTextParentWrapper = document.querySelector("#lblCategoryCopy");

        if (!categoryTextParentWrapper?.classList.contains("has-line-clamp")) return;

        var lineClampValue = document.querySelector("#CategoryCopyLineClampValue").value || 0;
        var catCopy = categoryTextParentWrapper.querySelector(".textDesc");

        if (lineClampValue > 0 && catCopy) {
            catCopy.style.cssText += "-webkit-line-clamp:" + lineClampValue;
            createReadMoreLessButton();
        }

        var parentElement = document.querySelector(".topheadbox");
        if (parentElement) {
            parentElement.classList.add("copyExtended");
        }

        if (!categoryTextWrapper) return;

        removeReadMoreLessButtonsInActualCategoryCopy();
        bindReadMoreLessEvents();
        showHideReadMoreLessButton();
    }

    function removeReadMoreLessButtonsInActualCategoryCopy() {
        document.querySelector("#readMore")?.remove();
    }

    function createReadMoreLessButton() {
        const createReadMoreLessButton = document.createElement("button");
        createReadMoreLessButton.innerHTML = categoryTextParentWrapper.dataset.readmoreLabel;
        createReadMoreLessButton.id = "read-more-less";
        createReadMoreLessButton.classList.add("hidden");
        readMoreReadLessButton = createReadMoreLessButton;

        categoryTextWrapper.insertAdjacentElement("afterend", readMoreReadLessButton);
    }

    function bindReadMoreLessEvents() {
        categoryTextWrapper.addEventListener("click", toggleReadMoreLess);
        readMoreReadLessButton.addEventListener("click", toggleReadMoreLess);

        window.addEventListener(
            "resize",
            debounce(() => {
                if (categoryTextWrapper?.length) {
                    resetReadMoreLess();
                    showHideReadMoreLessButton();
                }
            }, 100),
        );
    }

    function showHideReadMoreLessButton() {
        if (categoryTextWrapper.scrollHeight > categoryTextWrapper.clientHeight) {
            readMoreReadLessButton.classList.remove("hidden");
        }

        if (categoryTextWrapper.scrollHeight <= categoryTextWrapper.clientHeight) {
            readMoreReadLessButton.classList.add("hidden");
        }
    }

    function toggleReadMoreLess() {
        if (categoryTextWrapper.classList.contains("read-more")) {
            resetReadMoreLess();
        } else {
            categoryTextWrapper.classList.add("read-more");
            readMoreReadLessButton.innerHTML = categoryTextParentWrapper.dataset.readlessLabel;
        }
    }

    function resetReadMoreLess() {
        categoryTextWrapper.classList.remove("read-more");
        readMoreReadLessButton.innerHTML = categoryTextParentWrapper.dataset.readmoreLabel;
    }

    const debounce = (callback, wait) => {
        let timeoutId = null;

        return (...args) => {
            window.clearTimeout(timeoutId);

            timeoutId = window.setTimeout(() => {
                callback.apply(null, args);
            }, wait);
        };
    };

    // Expose globals
    window.SetVal = SetVal;
    window.ToggleSelectedFilter = ToggleSelectedFilter;
})(
    window,
    window.jQuery,
    window.Handlebars,
    window.segment || {},
    window.hashService,
    window.algoliaUtil,
    window.plpCategoryHeader,
);
