(function (window) {
    "use strict";

    const initCategoryHeaderSwiper = () => {
        const swiperSelector = "[data-category-swiper-init] .siblingLinks";
        const brandHeaderSwiper = $(swiperSelector);
        const brandHeaderSiblingSlides = brandHeaderSwiper?.find(".siblingSlide");

        if (!brandHeaderSwiper?.length) return;

        brandHeaderSwiper.addClass("swiper-container");
        brandHeaderSiblingSlides.addClass("swiper-wrapper");
        brandHeaderSiblingSlides.find("a").wrap('<div class="swiper-slide"></div>');

        new Swiper(swiperSelector, {
            slidesPerView: "auto",
        });
    };

    window.plpCategoryHeader = {
        initCategoryHeaderSwiper: initCategoryHeaderSwiper,
    };
})(window);
