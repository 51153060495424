(function (window, $, undefined) {
    "use strict";

    $(document).ready(function () {
        if (dynamicYieldHybridEnabledForPLP) {
            var categoryData = [];
            if (categoryBreadcrumbs?.length > 0) {
                for (var i = 0; i < categoryBreadcrumbs.length; i++) {
                    categoryData.push(categoryBreadcrumbs[i].displayName);
                }
            }
            categoryData.push(pageCategoryName);

            if ($(".dy-hybrid-campaign").length > 0) {
                $(".dy-hybrid-campaign").each(function () {
                    var target = $(this);
                    var dyCampaignSelector = target.attr("dy-campaign-selector");
                    var templateId = "#" + target.attr("template-id");

                    showDyCampaign("CATEGORY", categoryData, dyCampaignSelector, target, templateId);
                });
            }
        }
    });
})(window, window.jQuery);
