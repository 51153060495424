(function (window) {
    let esdContainer;
    let esdEmailInput;
    let esdConfirmEmailInput;
    let esdValidationRow;
    let enterEmailLabel = document.querySelector("[data-esd-validation-enter-email]");
    let noMatchLabel = document.querySelector("[data-esd-validation-no-match]");
    let notValidLabel = document.querySelector("[data-esd-validation-not-valid]");
    let esdCtas;
    let addToBagCallback = null;

    function initialiseProdEsd(esdAddToBagCallback) {
        addToBagCallback = esdAddToBagCallback;
        esdContainer = document.getElementById("esd-product-modal").querySelector(".esd-container");

        if (!esdContainer) return;

        esdEmailInput = esdContainer.querySelector("[data-esd-email]");
        esdConfirmEmailInput = esdContainer.querySelector("[data-esd-email-confirm]");
        esdValidationRow = esdContainer.querySelector(".esd-validation-row");
        enterEmailLabel = esdValidationRow.querySelector("[data-esd-validation-enter-email]");
        noMatchLabel = esdValidationRow.querySelector("[data-esd-validation-no-match]");
        notValidLabel = esdValidationRow.querySelector("[data-esd-validation-not-valid]");
        esdCtas = esdContainer.querySelectorAll(".esd-cta");
        esdEmailsMatch = false;

        bindEvents();
    }

    function bindEvents() {
        document.querySelector("[data-esd-email]").addEventListener("input", validateFields);

        document.querySelector("[data-esd-email-confirm]").addEventListener("input", validateFields);

        esdCtas.forEach((button) => {
            button.addEventListener("click", (event) => {
                event.preventDefault();

                if (validateFields()) {
                    addEsdToBag();
                }
            });
        });
    }

    const showError = (label) => {
        esdValidationRow.classList.remove("hide");
        label.classList.remove("hide");
    };

    const hideAllErrors = () => {
        esdValidationRow.classList.add("hide");
        enterEmailLabel.classList.add("hide");
        noMatchLabel.classList.add("hide");
        notValidLabel.classList.add("hide");
    };

    const validateEmail = (email) => {
        const emailPattern = /^[\w\.-]+(\+[\w-]*)?@([\w-]+\.)+[\w-]+$/i;
        return emailPattern.test(email);
    };

    const validateFields = () => {
        hideAllErrors();

        const email = esdEmailInput.value.trim();
        const confirmEmail = esdConfirmEmailInput.value.trim();

        if (!email || !confirmEmail) {
            showError(enterEmailLabel);
            return false;
        }

        if (!validateEmail(email)) {
            showError(notValidLabel);
            return false;
        }

        if (email !== confirmEmail) {
            showError(noMatchLabel);
            return false;
        }

        return true;
    };

    function addEsdToBag() {
        const esdData = JSON.parse(esdContainer.getAttribute("data-personalisation"))[0];
        const personalisationDetails = [
            {
                perType: esdData.PersonalisationType,
                value: esdConfirmEmailInput.value,
                variantId: esdData.RelatedVariantProductId,
                instructionText: esdData.InstructionText,
            },
        ];

        addToBagCoordinator._currentRequest.personalisationDetails = JSON.stringify(personalisationDetails);

        addToBagCallback();
    }

    window.ProdEsd = {
        initialiseProdEsd: initialiseProdEsd,
    };
})(window);
